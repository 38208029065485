import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { PatientsContext } from "../../context/PatientsContext";
import { UserContext } from "../../context/UserContext";
import { fetchDeletePatient } from "../../endpoints/fetchPatients";
import { isoDateFormatToBrazil } from "../../helpers/environment_helper";
import ConfirmModal from "../confirmModal/ConfirmModal";
import Tooltip from "../evolution/Tooltip";
import styles from "./PatientCard.module.css";

export default function PatientCard(props) {
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);
  const { userSetter } = useContext(UserContext);
  const { patientsSetter } = useContext(PatientsContext);
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState();

  const tooltipProps = {
    fontSize: "1rem",
    transform: "translateY(-4.3rem) translateX(1rem)"
  };

  // remove the selected patient
  const onRemove = (e) => {
    e.preventDefault();

    const handleOnRemove = async (inputText) => {
      if (inputText.value === "excluir") {
        const result = await fetchDeletePatient(props.patient._id, cookies.access_token);
        if (result) {
          patientsSetter(result.patientsLeft);
          userSetter(result.userChanged);
          setConfirm();
          messageSetter("Paciente deletado com sucesso!", "success");
        } else {
          messageSetter("Erro de conexão com o servidor.", "error");
        }
      } else {
        setConfirm();
        messageSetter("Confirmação digitada errada.", "warning");
      }
    };
    
    setConfirm(
      <ConfirmModal
        setCloseConfirmModal={setConfirm}
        handleSubmit={handleOnRemove}
        text={ `Digite a palavra <span>excluir</span>, para prosseguir com a exclusão do paciente ${ props.patient.name }.` }
        state={ "danger" }
      />
    );
  };

  const onEditPatient = () => {
    navigate(`/evolutions/${ props.patient._id }`, {
      state: {
        myPatient: props.myPatients
      }
    });
  };

  const onStatusChange = () => {
    props.handleOnChangeStatus(
      { isActive: !props.patient.isActive },
      props.patient._id,
      cookies.access_token
    );
  };

  const tooltipPropsStatus = {
    transform: "translateX(-35px) translateY(-45px)"
  };

  return (
    <>
      { confirm }
      <div className={ styles["patient-card-container"] }>
        <div className={ styles["patient-card-content"] }>
          <div className={ styles["patient-status-container"] }>
            <div className={ styles["patient-status-buttons"] }>
              <Tooltip
                text="editar paciente"
                propStyles={ tooltipProps }
              >
                <CiEdit className={ styles["edit-button"]} onClick={ onEditPatient }/>
              </Tooltip>
              { props.myPatients &&
                <Tooltip
                  text="remover paciente"
                  propStyles={ tooltipProps }
                >
                  <CiTrash className={ styles["remove-button"]} onClick={ onRemove }/>
                </Tooltip>
              }
            </div>
            <div
              className={ styles["patient-status"] }
              onClick={ onStatusChange }
            >
              <p>{ props.patient.isActive ? "Ativo" : "Inativo" }</p>
              <Tooltip text={ `${ props.patient.isActive ? "Desativar" : "Ativar" } Paciente` } propStyles={ tooltipPropsStatus }>
                <div
                  style={ { backgroundColor: `${ props.patient.isActive ? "#66A182" : "#F37471" }` } }
                  className={ styles["status"] }
                ></div>
              </Tooltip>
            </div>
          </div>
          <div className={ styles["patient-name-container"] }>
            <div>
              <p>{ props.patient.name }</p>
            </div>
          </div>
          <div className={ styles["patient-information-container"] }>
            <div>
              <p>IDADE:</p>
              <span>{ props.patient.age }</span>
            </div>
            <div>
              <p>EVOLUÇÕES:</p>
              <span>{ props.patient.evolutions?.list?.length > 0 ? props.patient.evolutions.list.length : "Sem evoluções." }</span>
            </div>
            <div>
              <p>ÚLTIMA EVOLUÇÃO:</p>
              <span>
                {
                  props.patient.evolutions.lastEvolutionDate
                    ? isoDateFormatToBrazil(props.patient.evolutions.lastEvolutionDate)
                    : "Sem evoluções."
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
