import React from "react";

import Card from "./Card";
import styles from "./Topography.module.css";

export default function Topography(props) {
  return (
    <div className={styles["main"]}>
      <Card
        handleOnClose={props.handleOnClose}
        customClass={props.customClass}
      >
        {props.children}
      </Card>
      <div
        className={`${styles["blur"]}`}
        onClick={props.clickOnBlur}
      />
    </div>
  );
}
