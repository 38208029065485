import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { brazilFormatDate } from "../../helpers/format_date_helper";
import Button from "../layout/Button";
import Divider from "../layout/Divider";
import Link from "../layout/Link";
import styles from "./DashboardPatientsCard.module.css";


export default function DashboardPatientsCard() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const patients = [...user.patients.active, ...user.patients.inactive];
  const lastOne = user.evolutionsInfo?.lastOne;
  const highOne = user.evolutionsInfo?.highOne;

  const userTotalPatientsContent = () => {
    return (
      <>
        <span className={ styles["totalPacientsTitle"] }>
          Total de pacientes
        </span>
        <span className={ styles["totalPacientsContent"] }>
          { <span>{ patients.length } </span> } 
          pacientes
        </span>
      </>
    );
  };

  // const amountOfInactivePatientsContent = () => {
  //   return (
  //     <>
  //       <span className={ styles["amountOfInactivePatientsTitle"] }>
  //         Total de pacientes desativados
  //       </span>
  //       <span className={ styles["amountOfInactivePatientsContent"] }>
  //         { <span>{ user.patients.inactive.length } </span> } 
  //         pacientes desativados
  //       </span>
  //     </>
  //   );
  // };

  const userTotalEvolutionsContent = () => {
    return (
      <>
        <span className={ styles["totalEvolutionsTitle"] }>
          Total de evoluções
        </span>
        <span className={ styles["totalEvolutionsContent"] }>
          { <span>{ user.evolutionsInfo.quantity } </span> }
          evoluções
        </span>
      </>
    );
  };

  const evolutionsRedirect = patientId => {
    navigate(`/evolutions/${ patientId }`, {
      state: {
        myPatient: true
      }
    });
  };

  const highestPatientEvolutionsContent = () => {
    const contentBlock = () => {
      if (highOne?.quantity === 0 || !highOne || Object.keys(highOne).length === 0) return "Não há evoluções cadastradas.";
      if (highOne?.id) {
        return (
          <>
            <p>{ highOne?.name }</p>
            <p>{ `${ highOne?.quantity } ${ highOne?.quantity === 1 ? "evolução" : "evoluções" }` }</p>
            <div className={ styles["link-separator"] }>
              <Link
                text={ "VISUALIZAR" }
                handleOnClick={ () => highOne?.id && evolutionsRedirect(highOne.id) }
              />
            </div>
          </>
        );
      } else if (highOne?.equalsQuantity) return `Há ${ String(highOne.equalsQuantity) } pacientes com a mesma quantidade de evoluções.`;
      else return "Não foi possível verificar seus pacientes.";
    };
    return (
      <>
        <span className={ styles["highestPatientEvolutionsTitle"] }>
          Paciente com mais evoluções
        </span>
        <span className={ styles["highestPatientEvolutionsContent"] }>
          <div className={ `${ styles["highestPatientEvolutionsFlex"] }` }>
            { contentBlock() }
          </div>
        </span>
      </>
    );
  };

  const lastEvolutionDateContent = () => {
    return (
      <>
        <span className={ styles["lastEvolutionDoneDateTitle"] }>
          Última evolução realizada
        </span>
        <span className={ styles["lastEvolutionDoneDateContent"] }>
          { lastOne ?
            <>
              <p>{ lastOne?.name }</p>
              <p>{ brazilFormatDate(lastOne?.date) }</p>
              <div className={ styles["link-separator"] }>
                <Link
                  text={ "VISUALIZAR" }
                  handleOnClick={  () => lastOne?.id && evolutionsRedirect(lastOne.id) }
                />
              </div>
            </>
            :
            "Não há evoluções cadastradas"
          }
        </span>
      </>
    );
  };

  return (
    <div className={ `${ styles["cardContainer"] }` }>
      <div className={ `${ styles["title-container"] }` }>
        <h3 className={ `${ styles["title"] }` }>Sumário de Pacientes</h3>
      </div>
      { patients.length > 0 ?
        <>
          <div className={ `${ styles["userTotalPatients"] }` }>
            { userTotalPatientsContent() }
          </div>
          <Divider />
          {/* <div className={ `${ styles["amountOfInactivePatients"] }` }>
            { amountOfInactivePatientsContent() }
          </div>
          <Divider /> */}
          <div className={ `${ styles["userTotalEvolutions"] }` }>
            { userTotalEvolutionsContent() }
          </div>
          <Divider />
          <div className={ `${ styles["highestPatientEvolutions"] }` }>
            { highestPatientEvolutionsContent() }
          </div>
          <Divider />
          <div className={ `${ styles["lastEvolutionDoneDate"] }` }>
            { lastEvolutionDateContent() }
          </div>
            
        </>
        :
        <span>Não há pacientes cadastrados.</span>
      }
      <div className={ styles["footer"] }>
        { patients.length > 0 &&
            <div className={ styles["button-separator"] }>
              <Button
                text={ "visualizar todos" }
                styleButton={ "buttonsPacientCard" }
                handleOnClick={ () => navigate("/patients") }
              />
            </div>
        }
        <div className={ styles["button-separator"] }>
          <Button
            text={ "adicionar paciente" }
            styleButton={ "buttonsPacientCard" }
            handleOnClick={ () => navigate("/newpatient") }
            disabled={ user && !user.hasAcceptedTOS }
          />
        </div>
      </div>
    </div>
  );
}
