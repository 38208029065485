import React from "react";

import Button from "../layout/Button";
import Input from "../layout/Input";
import styles from "./DateFilter.module.css";

export default function DateFilter({ sortEvolutionsByPeriod }) {

  const handleOnSort = () => {
    const initialDate = document.querySelector("#filterDateI")?.value;
    const finalDate = document.querySelector("#filterDateF")?.value;
    sortEvolutionsByPeriod(initialDate, finalDate);
  };

  return (
    <div className={ styles["filterMain"] }>
      <h4>FILTRO DE EVOLUÇÕES</h4>
      <div className={ styles["row1"] }>
        <div className={ styles["row1-content"] }>
          <Input
            type="date"
            name="filterDateI"
          />
        </div>
        <div className={ styles["row1-content"] }>
          <Input
            type="date"
            name="filterDateF"
          />
        </div>
      </div>
      <div className={ styles["row2"] }>
        <Button
          handleOnClick={ handleOnSort }
          text='Filtrar'
        />
      </div>
    </div>
  );
}
