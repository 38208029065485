import React from "react";

import UserForm from "../dashboardUserCard/userForm";
import Topography from "../layout/Topography";

export default function AddUser({ setCloseAddUser, handleSubmit }) {
  return (
    <Topography
      handleOnClose={ () => setCloseAddUser(false) }
      clickOnBlur={ () => setCloseAddUser(false) }
      customClass="modal"
    >
      <UserForm
        btnText="adicionar"
        handleSubmit={handleSubmit}
        formTitle="Adicionar Usuário"
        isNewUser
      />
    </Topography>
  );
}
