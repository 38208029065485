import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { PatientsContext } from "../../context/PatientsContext";
import { UserContext } from "../../context/UserContext";
import { fetchChangePatient, fetchGetPatients } from "../../endpoints/fetchPatients";
import { changePatientStatusStorage, verifyStorageTime } from "../../helpers/patient_helper";
import ConfirmModal from "../confirmModal/ConfirmModal";
import Loading from "../layout/Loading";
import PageName from "../layout/PageName";
import PatientCard from "../patient/PatientCard";
import styles from "./Patients.module.css";
import Input from "../layout/Input";

export default function Patients () {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId ? location.state.userId : localStorage.getItem("userId");
  const viewedUserName = location?.state?.userName;
  const [confirm, setConfirm] = useState();
  const [patientsSearch, setPatientsSearch] = useState();
  const { patients, patientsSetter } = useContext(PatientsContext);
  const { messageSetter } = useContext(MessageContext);
  const { userSetter } = useContext(UserContext);

  const getPatients = async () => {
    const result = await fetchGetPatients(location?.state?.userId && location.state.userId);
    if (result?.activeOnes && result?.inactiveOnes) {
      if (result.length === 0) {
        navigate("/dashboard");
      }
      patientsSetter(result.activeOnes.concat(result.inactiveOnes));
    }
  };

  const handleOnChangeStatus = async (patientChanges, patientId, accessToken) => {
    const p = patients;
    const patientIndex = p.findIndex(patient => (
      patient._id === patientId
    ));

    const handleSubmit = async () => {
      const timeFields = verifyStorageTime(patientId);
      if (timeFields.couldChangeStatus) {
        const fecthResult = await fetchChangePatient(patientChanges, patientId, accessToken);
        userSetter(fecthResult.user);
        patientsSetter(fecthResult.allPatients);
        messageSetter(
          `Paciente ${
            !patientChanges.isActive
              ? "desativado"
              : "ativado"
          } com sucesso!`,
          "success"
        );
        changePatientStatusStorage(patientId);
        setConfirm();
      } else {
        setConfirm();
        messageSetter(
          `Paciente alterado recentemente, favor espere ${
            Math.round(timeFields.timeLeft)
          } segundos.`,
          "warning"
        );
      }

    }; 

    setConfirm(
      <ConfirmModal
        setCloseConfirmModal={setConfirm}
        handleSubmit={ handleSubmit }
        text={ `
          ${ !patientChanges.isActive ? "Desativar" : "Ativar" } 
          paciente 
          ${ p[patientIndex].name }?
          Esta ação só podera ser refeita em 1min.
        ` }
        state={ "warn" }
        easyConfirm
      />
    );
  };

  useEffect(() => {
    if (!patients) {
      getPatients();
    }
  }, [userId, patients]);

  const handleChange = (e) => {
    const regex = new RegExp(e.target.value, "i");
    setPatientsSearch({
      patientName: e.target.value,
      matchPatients: patients.filter(p => p.name.match(regex))
    });
  };

  const patientsList =
    patientsSearch?.matchPatients
      ? patientsSearch?.matchPatients
      : patients;

  return (
    <>
      { confirm }
      <div className={ styles["patients-container"] }>
        { 
          patients ?
            <>
              <PageName
                title={
                  viewedUserName
                    ? `Pacientes de ${viewedUserName}`
                    : "Pacientes"
                }
              />
              <div className={ styles["search-container"] }>
                <Input
                  text="FILTRAR PACIENTES"
                  name="name"
                  type="search"
                  handleOnChange={handleChange}
                  value={patientsSearch?.patientName ? patientsSearch?.patientName : ""}
                  fromModal
                />
              </div>
              <div className={ styles["patients-card-container"] }>
                {
                  patients.length > 0 ?
                    patientsList.map(patient => {
                      return (
                        <PatientCard
                          key={ patient._id }
                          patient={ patient }
                          myPatients={ !viewedUserName }
                          handleOnChangeStatus={ handleOnChangeStatus }
                        />
                      );
                    })
                    :
                    <p className={ styles["no-pacients"] }>
                      Não há pacientes cadastrados.
                    </p>
                }
              </div>
            </>
            :
            <Loading pageName='patients' />
        }
      </div>
    </>
  );
}
