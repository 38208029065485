import React, { useState } from "react";

import { encryptWithAES } from "../../helpers/user_helper";
import Button from "../layout/Button";
import Input from "../layout/Input";
import Select from "../layout/Select";
import styles from "./userForm.module.css";
import ConfirmModal from "../confirmModal/ConfirmModal";

export default function UserForm ({
  user,
  handleSubmit,
  isNewUser,
  btnText,
  formTitle
}) {
  const [confirm, setConfirm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userChanged, setChangedUser] = useState(
    user ?
      {
        username: user.username,
        credential: {
          registry: user.credential.registry,
          type: user.credential.type
        },
        name: user.name
      } : {}
  );

  const credentialOptions = [
    { value: "crp", text: "CRP" },
    { value: "crefito", text: "Crefito" },
    { value: "crfa", text: "CRFa" },
    { value: "to", text: "TO" }
  ];

  const levelOptions = [
    { value: "admin", text: "administrador" },
    { value: "user", text: "usuário" }
  ];

  async function submit(e) {
    e.preventDefault();
    setIsLoading(true);
    if (
      (!("name" in userChanged)
      || !("username" in userChanged)
      || !("password" in userChanged && userChanged.password !== "")
      || !("level" in userChanged)
      || !("credential" in userChanged && "type" in userChanged.credential)
      || !("credential" in userChanged && "registry" in userChanged.credential && userChanged?.credential.registry !== ""))
      && isNewUser
    ) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor preencher todos os dados de usuário." }
          state={ "warn" }
          easyConfirm
          title="Ausencia de dados!"
          secondButton="fechar"
        />
      );
    } else {
      const userBoolResp = await handleSubmit(userChanged);
      if (userBoolResp) setIsLoading(false);
    }
  }

  function handleChange(e, passwd=false, isInputOnSelect=false) {
    if (isInputOnSelect) {
      setChangedUser({ ...userChanged, [e.target.name]: { type: e.target.related, registry: e.target.value } });
    } else {
      setChangedUser({
        ...userChanged,
        [e.target.name]: passwd ? encryptWithAES(e.target.value) : e.target.value
      });
    }
  }

  return (
    <>
      { confirm }
      <div className={styles["main"]}>
        <h4>{ formTitle }</h4>
        <form className={styles["form-container"]}>
          <div className={ styles["row1"] }>
            <div className={ styles["row1-content"] }>
              <Input
                text="NOME"
                name="name"
                type="text"
                handleOnChange={handleChange}
                value={userChanged.name ? userChanged.name : ""}
                fromModal
              />
            </div>
          </div>
          <div className={ styles["row2"] }>
            <div className={ styles["row2-content"] }>
              <Input
                fromModal
                text="USUÁRIO"
                name="username"
                type="text"
                handleOnChange={handleChange}
                value={userChanged.username ? userChanged.username : ""}
              />
            </div>
            <div className={ styles["row2-content"] }>
              <Input
                fromModal
                text="SENHA"
                name="password"
                type="password"
                handleOnChange={(e) => handleChange(e, true)}
              />
            </div>
          </div>
          <div className={ styles["row3"] }>
            <div className={styles["selectors"]}>
              <Select
                title="CREDENCIAL"
                name="credential"
                optionsAray={ credentialOptions }
                value={ userChanged.credential ? userChanged.credential.registry : ""}
                related={ userChanged.credential ? userChanged.credential.type : "" }
                handleOnChange={ e => handleChange(e, false, true) }
                fromModal
                optionAsTitle
                inputPlaceHolder="Registro..."
              />
            </div>
            { isNewUser &&
              <div className={styles["selectors"]}>
                <Select
                  title="NÍVEL"
                  name="level"
                  optionsAray={ levelOptions }
                  value={userChanged.level ? userChanged.level : ""}
                  handleOnChange={ handleChange }
                  fromModal
                />
              </div>
            }
          </div>
          <div className={ styles["row4"] }>
            <Button
              text={btnText}
              handleOnClick={ submit }
              isLoading={ isLoading }
            />
          </div>
        </form>
      </div>
    </>
  );
}