
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../img/flor.png";
import Login from "../login/Login";
import styles from "./Home.module.css";

export default function Home() {
  const navigate = useNavigate([]);
  
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className={`${styles["homeContainer"]}`}>
      <div className={styles["logoContainer"]}>
        <img className={styles["logo"]} src={logo} alt="logo" />
        <div className={`${styles["sloganContainer"]}`}>
          <div className={styles["brandContainer"]}>
            <h1 className={ styles["evolutions"] }>
                Evoluções 
              <label className={ styles["register"] }>
                  &copy;
              </label>
            </h1>
          </div>
          <h5 className={ styles["slogan"] }>Tenha tudo organizado e faça <span>florir</span> o melhor de cada um!</h5>
        </div>
      </div>
      <Login />
    </div>
  );
}
