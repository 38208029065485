export const systemMessages = (code, opcVar) => {
  let msgObj;
  switch (code) {
  case "M100":
    msgObj = {
      message: `Bem vindx de volta ${ opcVar }!`,
      type: "success"
    };
    break;
  case "M101":
    msgObj = {
      message: "O usuário/senha estão incorretos.",
      type: "error"
    };
    break;
  case "M102":
    msgObj = {
      message: "Usuário não informado!",
      type: "warning"
    };
    break;
  case "M103":
    msgObj = {
      message: "Senha não informada!",
      type: "warning"
    };
    break;
  case "M104":
    msgObj = {
      message: "Nome de usuário já exite, use outro!",
      type: "warning"
    };
    break;
  case "M105":
    msgObj = {
      message: "Credencial já exite, use outra!",
      type: "warning"
    };
    break;
  case "M106":
    msgObj = {
      message: "Novo usuário criado com sucesso",
      type: "success"
    };
    break;
  case "M107":
    msgObj = {
      message: "Conta editada com sucesso",
      type: "success"
    };
    break;
  case "M108":
    msgObj = {
      message: "Há outros usuários abaixo desse, delete-os antes!",
      type: "warning"
    };
    break;
  case "M109":
    msgObj = {
      message: "Usuário deletado com sucesso!",
      type: "success"
    };
    break;
  case "M110":
    msgObj = {
      message: "Erro ao procurar usuários. Recarregue a página.",
      type: "error"
    };
    break;
  case "M111":
    msgObj = {
      message: "Erro ao realizar login. Recarergue a página.",
      type: "error"
    };
    break;
  case "M112":
    msgObj = {
      message: "Erro ao editar conta. Recarregue a página.",
      type: "error"
    };
    break;
  case "M113":
    msgObj = {
      message: "Erro ao deletar usuário. Recarregue a página.",
      type: "error"
    };
    break;
  case "M114":
    msgObj = {
      message: "Erro ao carregar lista de usuários. Recarregue a página.",
      type: "error"
    };
    break;
  case "M115":
    msgObj = {
      message: "Erro ao cadastrar usuário! Recarregue a página.",
      type: ""
    };
    break;
  case "M116":
    msgObj = {
      message: "",
      type: ""
    };
    break;
  case "M117":
    msgObj = {
      message: "Erro ao procurar usuários geridos. Recarregue a página.",
      type: ""
    };
    break;
  case "M118":
    msgObj = {
      message: "Login realizado com sucesso",
      type: "success"
    };
    break;
  case "M119":
    msgObj = {
      message: "Erro ao carregar usuário. Recarregue a página.",
      type: "error"
    };
    break;
  case "M120":
    msgObj = {
      message: "Lista de usuários carregada com sucesso",
      type: "success"
    };
    break;
  case "M121":
    msgObj = {
      message: `Senha de ${ opcVar } alterada com sucesso`,
      type: "success"
    };
    break;
  case "M122":
    msgObj = {
      message: "Termos de uso aceitos",
      type: "success"
    };
    break;
  case "M123":
    msgObj = {
      message: "Erro ao salvar usuário. Recarregue a página.",
      type: "error"
    };
    break;
  case "M201":
    msgObj = {
      message: "Paciente criado com sucesso",
      type: "success"
    };
    break;
  case "M202":
    msgObj = {
      message: "Paciente alterado com sucesso",
      type: "success"
    };
    break;
  case "M203":
    msgObj = {
      message: "Paciente deletado com sucesso",
      type: "success"
    };
    break;
  case "M204":
    msgObj = {
      message: "Erro ao criar paciente. Recarregue a página.",
      type: "error"
    };
    break;
  case "M205":
    msgObj = {
      message: "Erro ao carregar pacientes. Recarregue a página.",
      type: "error"
    };
    break;
  case "M206":
    msgObj = {
      message: "Erro ao alterar paciente. Recarregue a página.",
      type: "error"
    };
    break;
  case "M207":
    msgObj = {
      message: "Erro ao deletar paciente. Recarregue a página.",
      type: "error"
    };
    break;
  case "M208":
    msgObj = {
      message: "Erro ao exportar pacientes. Recarregue a página.",
      type: "error"
    };
    break;
  case "M301":
    msgObj = {
      message: "Evolução criada com sucesso",
      type: "success"
    };
    break;
  case "M302":
    msgObj = {
      message: "Evolução deletada com sucesso",
      type: "success"
    };
    break;
  case "M303":
    msgObj = {
      message: "Erro ao criar evolução. Recarregue a página.",
      type: "error"
    };
    break;
  case "M304":
    msgObj = {
      message: "Erro ao filtrar evoluções. Recarregue a página.",
      type: "error"
    };
    break;
  case "M305":
    msgObj = {
      message: "Erro ao deletar evolução. Recarregue a página.",
      type: "error"
    };
    break;
  default:
    break;
  }
  return msgObj;
};