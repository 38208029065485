import axios from "axios";

import { getEnvironmentUrl } from "../helpers/environment_helper";

const baseUrl = getEnvironmentUrl();

export const fetchLoginUser = async (userLogging) => {
  try {
    const result = await axios.post(
      `${getEnvironmentUrl()}/auth/login`,
      userLogging
    );
    localStorage.setItem("userId", result.data.userId);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchGetUser = async (userId, accessToken) => {
  try {
    const response = await axios.get(
      `${baseUrl}/auth/${userId}/user`,
      { headers: { authorization: accessToken } }
    );
    localStorage.setItem("userLevel", response.data.level);
    localStorage.setItem("@user/activityChecker", "true");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOwnedUsers = async (userId, accessToken) => {
  try {
    const result = await axios.get(
      `${baseUrl}/auth/${ userId }/users`,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchEditUser = async (editedUser, userId, accessToken) => {
  try {
    const result = await axios.put(
      `${baseUrl}/auth/${userId}/change_user`,
      editedUser,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCreateUser = async (newUser, userId, accessToken) => {
  try {
    const result = await axios.post(
      `${baseUrl}/auth/${userId}/register`,
      newUser,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDeleteUser = async (userId, accessToken) => {
  try {
    const result = await axios.delete(
      `${baseUrl}/auth/${userId}/delete_user`,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};