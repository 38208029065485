import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { BsHeartFill } from "react-icons/bs";
import { CiCircleList, CiCircleRemove, CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import { getPathName } from "../../helpers/environment_helper";
import { getLogo } from "../../helpers/logo_helper";
import Link from "./Link";
import styles from "./Navbar.module.css";
import { UserContext } from "../../context/UserContext";

export default function Navbar() {
  const navigate = useNavigate([]);
  // eslint-disable-next-line no-unused-vars
  const [_, setCookies] = useCookies();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [properties, setProperties] = useState({});
  const { user } = useContext(UserContext);

  const showsMenu = (menuVisibility=false) => {
    let properties = {};
    if (window.innerWidth < 1060 && menuVisibility) {
      properties.menuDisplay = "0rem";
      properties.iconsDirection = "row";
      properties.iconsTranslaction = "-3rem";
    } else if (window.innerWidth < 1060 && !menuVisibility) {
      properties.menuDisplay = "-20rem";
      properties.iconsDirection = "row-reverse";
      properties.iconsTranslaction = "0rem";
    } else {
      properties.menuDisplay = "0rem";
      properties.iconsDirection = "row";
      properties.iconsTranslaction = "-3rem";
    }
    document.getElementById("app").style.overflow =
      menuVisibility
        ? "hidden"
        : "auto";
    return properties;
  };
  
  const handleMenuClick = () => {
    setMenuVisibility(!menuVisibility);
    setProperties(showsMenu(!menuVisibility));
  };

  const handleOptionClick = (path) => {
    navigate(path);
    window.innerWidth < 1060 && handleMenuClick();
  };

  const logout = () => {
    handleMenuClick();
    setCookies("access_token", "");
    window.localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    Object.keys(properties).length === 0 && setProperties(showsMenu());
  }, []);
  
  let timerMenuDisplay;
  let higherCalculated = false;
  let lowerCalculated = false;
  const timerToSetMenuDisplay = () => {
    if(window.innerWidth < 1060 && !lowerCalculated) {
      lowerCalculated = true;
      higherCalculated = false;
      clearTimeout(timerMenuDisplay);
      timerMenuDisplay = setTimeout(() => setProperties(showsMenu()), 200);
    }
    if(window.innerWidth > 1060 && !higherCalculated) {
      higherCalculated = true;
      lowerCalculated = false;
      clearTimeout(timerMenuDisplay);
      timerMenuDisplay = setTimeout(() => setProperties(showsMenu()), 200);
    }
  };

  window.addEventListener("resize", timerToSetMenuDisplay);

  return (
    <>
      <div
        className={ styles["overlay"] }
        onClick={ handleMenuClick }
        style={ { display: menuVisibility ? "block" : "none" } }
      ></div>
      <div
        className={ styles["menu-icons"] }
        onClick={ handleMenuClick }
        style={ {
          flexDirection: properties.iconsDirection
        } }
      >
        <CiCircleList style={ { transform: `translateX(${ properties.iconsTranslaction })` } } className={ styles["menu-icon"] } />
        <CiCircleRemove style={ { transform: `translateX(${ properties.iconsTranslaction })` } } className={ styles["menu-icon"] } />
      </div>
      <nav
        className={ styles["container"] }
        style={ {
          transform: `translateX(${properties.menuDisplay})`
        } }
      >
        <div className={ styles["content"] }>
          <div className={ styles["logout-container"] }>
            <Link
              text={ "sair" }
              handleOnClick={ logout }
              fromMenu
            />
          </div>
          <div className={ styles["navbar-header"] }>
            <div className={ `${ styles["image-container"] }` }>
              <img
                src={getLogo(user?.enterpriseName)}
                alt='logotipo'
                className={ `${ styles["logo"] }` }
              />
            </div>
            <div className={ styles["avatar-container"] }>
              <div className={ styles["avatar-image"] }>
                { user?.avatarImage
                  ? <img
                    src={ user?.avatarImage }
                    alt="avatar"
                  />
                  : <CiUser className={ styles["avatar-default"] }/>
                }
                
              </div>
            </div>
          </div>
          <div className={ styles["navbar-items"] }>
            <ul className={ styles["navbar-list"] }>
              <li
                className={ `
                  ${styles["navbar-item"]}
                  ${styles[getPathName().match(/\/dashboard/) && "link-active"]}
                ` }
              >
                <Link
                  disabled={ user && !user.hasAcceptedTOS }
                  text="VISÃO GERAL"
                  handleOnClick={() => handleOptionClick("/dashboard")}
                  fromMenu
                />
              </li>
              <li
                className={ `
                  ${styles["navbar-item"]}
                  ${styles[getPathName().match(/(\/patient.+)|(\/evolution.+)/) && "link-active"]}
                ` }
              >
                <Link
                  disabled={ user && !user.hasAcceptedTOS }
                  text="PACIENTES"
                  handleOnClick={() => handleOptionClick("/patients")}
                  fromMenu
                />
              </li>
              <li
                className={ `
                  ${styles["navbar-item"]}
                  ${styles[getPathName().match(/\/newpatient/) && "link-active"]}
                ` }
              >
                <Link
                  disabled={ user && !user.hasAcceptedTOS }
                  text="ADICIONAR PACIENTE"
                  handleOnClick={() => handleOptionClick("/newpatient")}
                  fromMenu
                />
              </li>
            </ul>
          </div>
        </div>
        <div className={ styles["lovely-disclaimer"] }>
          feito com <BsHeartFill className={ styles["heart-icon"] } />
        </div>
      </nav>
    </>
  );
}