import React from "react";

import exitIcon from "./../../img/menu-exit.svg";
import styles from "./Card.module.css";

export default function Card(props) {
  return (
    <div
      className={`${styles["main"]}
      ${styles[props.customClass]}
      ${props.activeShadow && styles["shadow"]}`}
    >
      {props.handleOnClose &&
        <div className={styles["btnClose"]}>
          <img src={exitIcon} alt='close modal' onClick={props.handleOnClose}/>
        </div>
      }
      {props.children}
    </div>
  );
}
