import React from "react";

import styles from "./PageName.module.css";


export default function PageName({ title, newPatinetPage }) {
  return (
    <div className={ `${styles["page-container"]} ${styles[newPatinetPage && "new-patient-page"]}` }>
      <h1 className={styles["page-name"]}>{ title }</h1>
    </div>
  );
}
