import expressarLogo from "../img/expressarLogo.png";
import munchenLogo from "../img/munchenLogo.png";
import pictoLogo from "../img/pictoLogo.png";

export const getLogo = (enterpriseName) => {
  switch (enterpriseName) {
  case "munchenPsicologia":
    return munchenLogo;
  case "terapiaOcupacional":
    return pictoLogo;
  case "expressar":
    return expressarLogo;
  default:
    break;
  }
};