import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { fecthCreatePatient } from "../../endpoints/fetchPatients";
import PageName from "../layout/PageName";
import PatientForm from "../patient/PatientForm";
import styles from "./NewPatient.module.css";
import { UserContext } from "../../context/UserContext";
import { PatientsContext } from "../../context/PatientsContext";


export default function NewPatient() {
  const navigate = useNavigate([]);
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);
  const { userSetter } = useContext(UserContext);
  const { patientsSetter } = useContext(PatientsContext);

  const createPatient = async (newPatient) => {
    const result = await fecthCreatePatient(newPatient, cookies.access_token);
    if (result) {
      messageSetter("Paciente adicionado com sucesso!", "success");
      userSetter(result.userChanged);
      patientsSetter(result.patients);
      navigate(`/evolutions/${ result.patientId }`, {
        state: {
          myPatient: true
        }
      });
    } else {
      messageSetter("Erro de conexão com o servidor.", "error");
    }
    if (result) return !!result;
  };

  return (
    <div className={styles["container"]}>
      <PageName title={ "Adicionar Paciente" } newPatinetPage />
      <p className={styles["subtitle"]}>Adicione um paciente para então adicionar uma evolução!</p>
      <PatientForm btnText="adicionar paciente" handleSubmit={createPatient} newOne />
    </div>
  );
}
