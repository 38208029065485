export const getEnvironmentUrl = () => {
  if(document.location.hostname === "localhost") {
    return "http://localhost:4001";
  } else {
    return "/api";
  }
};

export const isoDateFormatToBrazil = date => date.split("-").reverse().join("/");

export const getPathName = () => window.location.pathname;