import React, { createContext, useState } from "react";

export const EvolutionsContext = createContext();

export const EvolutionsProvider = ({ children }) => {
  const [patientEvolutions, setPatientEvolutions] = useState(null);

  const patientEvolutionsSetter = (evolutions) => {
    setPatientEvolutions(evolutions);
  };

  const providerValues ={
    patientEvolutions,
    patientEvolutionsSetter,
  };

  return (
    <EvolutionsContext.Provider
      value={ providerValues }
    >
      { children }
    </EvolutionsContext.Provider>
  );
};