import React from "react";
import { FaAngleDown } from "react-icons/fa";

import { isoDateFormatToBrazil } from "../../helpers/environment_helper";
import { getUserLevel } from "../../helpers/user_helper";
import styles from "./PatientSection.module.css";
import { getRelated } from "../../helpers/patient_helper";

function PatientSection({
  patient,
  valueToCollect,
  evolutionsFromFilter,
  showValues,
  setShowValues
}) {

  const handleValuesVisibility = () => {
    setShowValues(!showValues);
  };

  const familyOptions = [
    { value: "dad", text: "PAI" },
    { value: "mom", text: "MÃE" },
    { value: "grandfather", text: "AVÔ" },
    { value: "grandmother", text: "AVÓ" },
    { value: "uncle", text: "TIO" },
    { value: "aunt", text: "TIA" }
  ];

  const patientInformationsContent = () => {
    return (
      <div className={ styles["patient-section-informations"] }>
        <div>
          <span className={ `${ styles["information-title"] }` }>IDADE: </span>
          <span className={ `${ styles["information-content"] }` }>{ patient.age }</span>
        </div>
        <div>
          <span className={ `${ styles["information-title"] }` }>DATA NASC.: </span>
          <span className={ `${ styles["information-content"] }` }>{ isoDateFormatToBrazil(patient.birth) }</span>
        </div>
        { patient.dad?.name && patient.dad.name !== ""
          && <div className={ `${ !patient.dad && styles["show-dad"] }` }>
            <span className={ `${ styles["information-title"] }` }>{ getRelated(patient.dad?.related, familyOptions) }: </span>
            <span className={ `${ styles["information-content"] }` }>{ patient.dad?.name }</span>
          </div>
        }
        { patient.mom?.name && patient.mom.name !== ""
          && <div className={ `${ !patient.mom && styles["show-mom"] }` }>
            <span className={ `${ styles["information-title"] }` }>{ getRelated(patient.mom?.related, familyOptions) }: </span>
            <span className={ `${ styles["information-content"] }` }>{ patient.mom?.name }</span>
          </div>
        }
        <div>
          <span className={ `${ styles["information-title"] }` }>QNT. DE EVOLUÇÕES: </span>
          <span className={ `${ styles["information-content"] }` }>{ patient.evolutionsQuantity }</span>
        </div>
        <div className={ `${ !patient.phone && styles["show-phone"] }` }>
          <span className={ `${ styles["information-title"] }` }>TELEFONE: </span>
          <span className={ `${ styles["information-content"] }` }>{ patient.phone }</span>
        </div>
      </div>
    );
  };

  const patientValuesInformation = () => {
    if(getUserLevel() !== "user") {
      return (
        <div className={ styles["patient-section-values"] }>
          <div
            onClick={ handleValuesVisibility }
            className={ `${ styles["value-header"]} ${ showValues && styles["value-header-expanded"] }` }
          >
            <div
              className={ `${ styles["value-header-chevron"] }` }
              style={ { transform: `rotate(${ showValues ? 180 : 0 }deg)` } }
            >
              <FaAngleDown />
            </div>
            <span>EXIBIR VALORES</span>
          </div>
          <div className={ `${ showValues && styles["value-itens-show"] } ${ styles["value-itens"] }` }>
            <div>
              <span className={ styles["information-title"] }>VALOR DA SESSÃO: </span>
              <span className={ styles["information-content"] }>R$ { patient.value }</span>
            </div>
            <div>
              <span className={ styles["information-title"] }>
                { evolutionsFromFilter ? "VALOR TOTAL" : "VALOR TOTAL DO FILTRO" }:
              </span>
              <span className={ styles["information-content"] }>
                R$ { valueToCollect ? valueToCollect : "--" }
              </span>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={ styles["patient-section-container"] }>
      <div className={ styles["patient-section-content"] }>
        <div className={ styles["patient-section"] }>
          <div className={ styles["patient-section-name"] }>
            <span>{ patient.name }</span>
          </div>
          { patientInformationsContent() }
        </div>
        { patientValuesInformation() }
      </div>
    </div>
  );
}

export default PatientSection;