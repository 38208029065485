import React from "react";

import styles from "./Button.module.css";

export default function Button({
  text,
  handleOnClick,
  secondary,
  disabled,
  isLoading
}) {
  return (
    <div
      className={ `
        ${ styles["button-container"] }
        ${ styles[secondary && "button-container-secondary"] }
        ${ styles[disabled || isLoading && "disabled"] }
      ` }>
      <div
        className={`
          ${styles["button"]}
          ${styles[secondary && "button-secondary"]}
        `}
        onClick={!disabled && !isLoading ? handleOnClick : undefined}
      >
        {!isLoading
          ? <span>{ text.toUpperCase() }</span>
          : <span className={styles["loading-icon"]}>
            <svg viewBox="22 22 44 44" >
              <circle
                cx="44"
                cy="44"
                r="20.2"
                fill="none"
                strokeWidth="3.6"
                color="#606060"
              ></circle>
            </svg>
          </span>
        }
      </div>
    </div>
  );
}
