import React, { createContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState([]);

  const messageSetter = (label, action, remove=false) => {
    if (remove) {
      setMessage(prevMessages => prevMessages.splice(1));
    } else {
      const id = uuidv4();
      setMessage(prevMessages => [
        ...prevMessages,
        {
          id: id,
          message: label,
          type: action
        }
      ]);
    }
  };

  const providerValues ={
    message,
    messageSetter,
  };

  return (
    <MessageContext.Provider
      value={ providerValues }
    >
      { children }
    </MessageContext.Provider>
  );
};