import React, { useState } from "react";

import Input from "../layout/Input";
import styles from "./TermsOfService.module.css";

export default function TermsOfService({ blockButton }) {
  const [terms, setTerms] = useState([]);
  const termsToAccept = [
    "mainTerms",
    "payments",
    "access",
    "security"
  ];

  const handleOnCheckItem = (e) => {
    const termName = e.target.name;
    if (terms.includes(termName)) {
      setTerms(terms.filter(term => term !== termName));
      blockButton(true);
    } else {
      setTerms([...terms, termName]);

      if (
        termsToAccept.length === [...terms, termName].length
        && termsToAccept.every(value => [...terms, termName].includes(value))
      ) {
        blockButton(false);
      } else {
        blockButton(true);
      }
    }
  };

  return (
    <>
      <div className={ styles["title-container"] }>
        <div className={ styles["checkbox"] }>
          <Input
            name="mainTerms"
            type="checkbox"
            handleOnChange={ handleOnCheckItem }
          />
        </div>
        <h4 className={ styles["title"] }>
          TERMOS GERAIS:
        </h4>
      </div>
      <p className={ styles["text"] }>
        Esse não é um contrato, mas sim um termo que deve ser aceito para a utilização do serviço disponibilizado.
      </p>
      <br />
      <p className={ styles["text"] }>
        Este app foi criado para um objetivo único e direto que é auxiliar na organização 
        de atendimentos psicológicos. Ele já saiu de sua versão inicial, porém poderá receber atualizações para melhorar sua experiência.
      </p>
      <div className={ styles["title-container"] }>
        <div className={ styles["checkbox"] }>
          <Input
            name="payments"
            type="checkbox"
            handleOnChange={ handleOnCheckItem }
          />
        </div>
        <h4 className={ styles["title"] }>
          ENCARGOS:
        </h4>
      </div>
      <p className={ styles["text"] }>
        A manutenção e disponibilização deste site gera um certo custo mensal.
        <br />
        Este custo será cobrado por usuário cadastrado, seja ele de nível administrador ou usuário.
        <br />
        O valor deste custo será informado no fim do primeiro mês de uso, sendo assim o primeiro mês será isento.
      </p>
      <div className={ styles["title-container"] }>
        <div className={ styles["checkbox"] }>
          <Input
            name="access"
            type="checkbox"
            handleOnChange={ handleOnCheckItem }
          />
        </div>
        <h4 className={ styles["title"] }>
          ACESSO:
        </h4>
      </div>
      <p className={ styles["text"] }>
        Para se acessar o site e manter tudo organizado, será requisitado algumas informções que deverão ser fornecidas.
      </p>
      <br />
      <p className={ styles["text"] }>
        Para o acesso ao site será solicitado o nome, número da credencial do registro profissional, um usuário e uma senha.
      </p>
      <br />
      <p className={ styles["text"] }>
        Para registrar um paciente será solicitado o nome, a data de nascimento, o nome de algum parentesco, o telefone e o valor cobrado por cada sessão de atendimento. 
        Esse último para calcular os valores por período e total de todas sessões.
      </p>
      <div className={ styles["title-container"] }>
        <div className={ styles["checkbox"] }>
          <Input
            name="security"
            type="checkbox"
            handleOnChange={ handleOnCheckItem }
          />
        </div>
        <h4 className={ styles["title"] }>
          SEGURANÇA:
        </h4>
      </div>
      <p className={ styles["text"] }>
        O site tem um backup que é realizado todos os dias entre 20h e 23h, dependendo da diferença de horários do local onde o servidor está 
        hospedando o site e o horário do Brasil, podendo variar conforme os horários de verão em outros países.
      </p>
      <br />
      <p className={ styles["text"] }>
        Caso uma taxa venha a existir no futuro e por algum motivo não seja feito o devido pagamento, o administrador do site tem o direito de vetar o acesso do usuário.
        <br />
        Caso isso ocorra o usuário tem o direito de solicitar todos os registros de seus pacientes e atendimentos sem custo algum.
      </p>
      <br />
      <p className={ styles["text"] }>
        Mesmo que o backup ocorra não se pode eliminar a chance de ocorrer algum imprevisto que se perca algum dado, por isso é recomendado que o usuario faça seu próprio backup, 
        e mantenha sua máquina protegida de ataques cybernéticos. E mesmo que o servidor seja protegido, caso ocorra algum desastre e se perca algum dado, nem o site e o desenvolvedor se responsabilizarão pelos danos.
      </p>
      <p className={ styles["text"] }>
        Ao aceitar estes termos será enviado um email ao desenvolvedor com as informações de seu ip externo, o link do site, a data e hora atual e 
        seu nome de cadastro. Isto é para que fique registrado seu aceite dos termos.
      </p>
      <br />
      <p className={ styles["text"] }>
        Nenhum de seus dados serão utilizados para outros fins, somente para o correto funcionamento do app. 
        Para o correto funcionamento do site precisa-se da utilização de cookies.
        Lembro também que qualquer dúvida você poderá contatar o desenvolvedor pelo email munchenc.suporte@gmail.com.
      </p>
      <br /> <br />
      <p className={ styles["text"] }>
        Dito isso, ao clicar em {"\"concordo\""}, você irá concordar com os termos de serviço.
      </p>
    </>
  );
}