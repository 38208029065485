import React from "react";
import { CiWarning } from "react-icons/ci";

import Button from "../layout/Button";
import Input from "../layout/Input";
import Topography from "../layout/Topography";
import styles from "./ConfirmModal.module.css";

export default function ConfirmModal({
  setCloseConfirmModal,
  handleSubmit,
  text,
  state,
  easyConfirm,
  title="Confirme sua ação",
  firstButton="confirmar",
  secondButton="cancelar"
}) {
  function submit() {
    const inputText = document.getElementById("input-text");
    handleSubmit(inputText);
  }

  return (
    <Topography
      clickOnBlur={ () => !easyConfirm && setCloseConfirmModal() }
      handleOnClose={ () => setCloseConfirmModal() }
    >
      <div className={`${styles["main"]}`}>
        <div className={ `
          ${styles["title"] }
          ${ styles[state === "danger" && "danger"] }
          ${ styles[state === "warn" && "warn"] }
        ` }>
          <CiWarning />
          <h4>{ title }</h4>
        </div>
        <p dangerouslySetInnerHTML={ { __html: text } } />
        { !easyConfirm &&
          <div className={ styles["input-container"] }>
            <Input
              id='input-text'
              type='text'
              placeholder="digite sua confirmação..."
              fromModal
            />
          </div>
        }
        <div className={ `${ styles[easyConfirm ? "double-buttons" : "buttons-container"] }` }>
          { easyConfirm &&
            <div className={ `${ styles[easyConfirm ? "double-button" : "button-container"] }` }>
              <Button secondary text={ secondButton } handleOnClick={ () => setCloseConfirmModal() } />
            </div>
          }
          { handleSubmit &&
            <div className={ `${ styles[easyConfirm ? "double-button" : "button-container"] }` }>
              <Button text={ firstButton } handleOnClick={submit} />
            </div>
          }
        </div>
      </div>
    </Topography>
  );
}