import React from "react";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";

import styles from "./Footer.module.css";

export default function Footer() {
  const developer = "Cidmar München Oliveira";
  const role = "WEB Developer";

  return (
    <footer className={styles["footer-container"]}>
      <ul className={styles["social-list"]}>
        <li>
          <a
            href={"https://github.com/ciiDmo"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <FaGithub />
          </a>
        </li>
        <li>
          <a
            href={"https://www.linkedin.com/in/cidmar-oliveira-2b8166231/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <FaLinkedin />
          </a>
        </li>
        <li>
          <a
            href={"_blank"}
            target={"https://www.instagram.com/ciidmo/"}
            rel={"noreferrer"}
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
      <div className={styles["developer-container"]}>
        <p>{ developer.toUpperCase() }</p>
        <span>-</span>
        <p>{ role.toUpperCase() }</p>
      </div>
    </footer>
  );
}
