import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";

import { EvolutionsContext } from "../../context/EvolutionsContext";
import { MessageContext } from "../../context/MessageContext";
import { PatientsContext } from "../../context/PatientsContext";
import { UserContext } from "../../context/UserContext";
import { fetchDeleteEvolution, fetchGetEvolutions } from "../../endpoints/fetchEvolutions";
import { fetchChangePatient } from "../../endpoints/fetchPatients";
import { changePatientStatusStorage, verifyStorageTime } from "../../helpers/patient_helper";
import { convertStringDateToExtendedDate } from "../../helpers/user_helper";
import ConfirmModal from "../confirmModal/ConfirmModal";
import DateFilter from "../evolution/DateFilter";
import EvolutionCard from "../evolution/EvolutionCard";
import EvolutionForm from "../evolution/EvolutionForm";
import Button from "../layout/Button";
import Loading from "../layout/Loading";
import Topography from "../layout/Topography";
import PatientForm from "../patient/PatientForm";
import PatientSection from "../patient/PatientSection";
import styles from "./Evolutions.module.css";

export default function Evolutions() {
  const { patientId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);
  const { userSetter } = useContext(UserContext);
  const { patientsSetter } = useContext(PatientsContext);
  const { patientEvolutions, patientEvolutionsSetter } = useContext(EvolutionsContext);
  
  const location = useLocation();
  const myPatient = location?.state?.myPatient;

  const [patient, setPatient] = useState({});
  const [evolutionsFromFilter, setEvolutionsFromFilter] = useState([]);
  const [valueToCollect, setValueToCollect] = useState(0);
  const [showValues, setShowValues] = useState(false);
  const [confirm, setConfirm] = useState();

  // Forms controllers
  const [evolutionForm, setEvolutionForm] = useState(false);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [showPatientForm, setShowPatientForm] = useState(false);

  // Fetch all evolutions and the patient
  const fetchEvolutions = async () => {
    const result = await fetchGetEvolutions(patientId);
    patientEvolutionsSetter(result.evolutions);
    setPatient(result.patient);
  };

  // Delete a evolution
  const handleDeleteEvolution = async (evolutionId) => {
    const response = await fetchDeleteEvolution(patientEvolutions, evolutionId, cookies.access_token);
    if (response) {
      patientEvolutionsSetter(response.remainingEvolutions);
      userSetter(response.user);
    }
  };

  // Change the patient informations
  const handleOnEditPatient = async (patientChanges, isForm=false) => {
    if (isForm) {
      const result = await fetchChangePatient(patientChanges, patientId, cookies.access_token);
      if (result.patientChanged) {
        setShowPatientForm(false);
        setPatient(result.patientChanged);
        userSetter(result.user);
        patientsSetter(result.allPatients);
      }
      if (result) return !!result;
      messageSetter("Paciente alterado com sucesso!", "success");
    } else {
      const handleSubmit = async () => {
        const timeFields = verifyStorageTime(patientId);
        if (timeFields.couldChangeStatus) {
          const result = await fetchChangePatient(patientChanges, patientId, cookies.access_token);
          userSetter(result.user);
          patientsSetter(result.allPatients);
          setPatient(result.patientChanged);
          messageSetter(
            `Paciente ${
              !patientChanges.isActive
                ? "desativado"
                : "ativado"
            } com sucesso!`,
            "success"
          );
          changePatientStatusStorage(patientId);
          setConfirm();
          if (result) return !!result;
        } else {
          setConfirm();
          messageSetter(
            `Paciente alterado recentemente, favor espere ${
              Math.round(timeFields.timeLeft)
            } segundos.`,
            "warning"
          );
        }
      }; 
    
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          handleSubmit={ handleSubmit }
          text={ `
              ${ !patientChanges.isActive ? "Desativar" : "Ativar" } 
              paciente 
              ${ patient.name }?
              Esta ação só podera ser refeita em 1min.
            ` }
          state={ "warn" }
          easyConfirm
        />
      );
    }
  };

  // Close evolution form and update evolutions list when a evolution creation was done
  const handleOnCreate = (result) => {
    setEvolutionForm(!evolutionForm);
    patientEvolutionsSetter(result.updatedEvolutions);
    userSetter(result.user);
  };

  // calcule the value to collect from all evolutions with presence or ausence not justified
  const calculeEvolutionsValue = (evolutionsArray) => {
    const amountOfEvolutionsToCollect =
      evolutionsArray?.filter(evolution => {
        return (
          evolution.isPresent ||
          (!evolution.isPresent && !evolution.isJustified)
        );
      });
    setValueToCollect(amountOfEvolutionsToCollect.length * patient.value);
  };

  // Sort the evolutions by date inputed on the filter form
  const sortEvolutionsByPeriod = (initialDate, finalDate) => {
    if (!initialDate || !finalDate) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor preencher os dois campos de data." }
          state={ "warn" }
          easyConfirm
          title="Ausencia de dados!"
          secondButton="fechar"
        />
      );
    } else if (
      convertStringDateToExtendedDate(initialDate) > 0
      || convertStringDateToExtendedDate(finalDate) > 0
    ) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor selecione uma data válida." }
          state={ "warn" }
          easyConfirm
          title="Erro de data!"
          secondButton="fechar"
        />
      );
    } else {
      const filterEvolutions = 
      patientEvolutions.filter(evolution => (
        (evolution.date >= initialDate) && (evolution.date <= finalDate)
      ));
      setEvolutionsFromFilter(filterEvolutions);
      calculeEvolutionsValue(filterEvolutions);
      setShowFilterForm(!showFilterForm);
    }
  };

  // Clear the filtered evolutions previouly filtered in the filter form
  const clearEvolutionsFilter = () => {
    setEvolutionsFromFilter([]);
    calculeEvolutionsValue(patientEvolutions);
  };

  useEffect(() => {
    if (!patientEvolutions) {
      fetchEvolutions();
    }
  }, [patientEvolutions]);

  useEffect(() => {
    if (patientEvolutions) {
      calculeEvolutionsValue(patientEvolutions);
    }
  }, [patient?.value, patientEvolutions]);

  const evolutionsContent = () => {
    const evolutionsList = () => {
      if (evolutionsFromFilter.length > 0) return evolutionsFromFilter;
      else return patientEvolutions;
    };

    return (
      <div className={ styles["evolutions-card-container"] }>
        {
          patientEvolutions.length > 0
            ? (
              evolutionsList().map((evolution) => (
                <EvolutionCard
                  evolution={ evolution }
                  key={ evolution._id }
                  handleDeleteEvolution={ () => handleDeleteEvolution(evolution._id) }
                />
              ))
            )
            : (
              <p className={ styles["noEvo"] }>
                Não há evoluções registradas para este paciente.
              </p>
            )
        }
      </div>
    );
  };

  const handleOnCloseEvolutionForm = () => setEvolutionForm(!evolutionForm);

  return (
    <div className={ styles["evolutions-container"] }>
      {
        evolutionForm &&
          <Topography
            clickOnBlur={ handleOnCloseEvolutionForm }
            handleOnClose={ handleOnCloseEvolutionForm }
            customClass="modal"
          >
            <EvolutionForm btnText="Adicionar Evolução" onCreate={ handleOnCreate } />
          </Topography>
      }
      {
        showFilterForm &&
          <Topography
            clickOnBlur={ () => setShowFilterForm(!showFilterForm) }
            handleOnClose={ () => setShowFilterForm(!showFilterForm) }
          >
            <DateFilter sortEvolutionsByPeriod={ sortEvolutionsByPeriod } />
          </Topography>
      }
      {
        showPatientForm &&
          <Topography
            clickOnBlur={ () => setShowPatientForm(false) }
            handleOnClose={ () => setShowPatientForm(false) }
            customClass="modal"
          >
            <PatientForm
              handleSubmit={ e => handleOnEditPatient(e, true) }
              btnText="Concluir Edição"
              patient={ patient }
            />
          </Topography>
      }
      { confirm }
      { 
        (Object.keys(patient).length > 0 && !!patientEvolutions) ? (
          <>
            <PatientSection
              patient={ patient }
              valueToCollect={ valueToCollect }
              evolutionsFromFilter={ evolutionsFromFilter.length === 0 }
              showValues={ showValues }
              setShowValues={ setShowValues }
            />
            <div className={ styles["evolutions-navbar"] }>
              {
                myPatient &&
                  <>
                    <div className={ styles["navbar-buttons"] }>
                      <Button
                        handleOnClick={ () => setEvolutionForm(!evolutionForm) }
                        text={ "ADICIONAR EVOLUÇÃO" }
                      />
                    </div>
                    <div className={ styles["navbar-buttons"] }>
                      <Button
                        text={ "EDITAR PACIENTE" }
                        handleOnClick={ () => setShowPatientForm(true) }
                      />
                    </div>
                    <div className={ styles["navbar-buttons"] }>
                      <Button
                        text={ patient.isActive ? "DESATIVAR PACIENTE" : "ATIVAR PACIENTE" }
                        handleOnClick={ () => handleOnEditPatient({ isActive: !patient.isActive }) }
                      />
                    </div>
                  </>
              }
              <div className={ styles["navbar-buttons"] }>
                <Button
                  handleOnClick={ () => {
                    evolutionsFromFilter.length > 0 ? clearEvolutionsFilter() : setShowFilterForm(!showFilterForm);
                  } }
                  text={ evolutionsFromFilter.length > 0 ? "LIMPAR FILTRO" : "FILTRAR EVOLUÇÕES" }
                />
              </div>
            </div>
            <h2>{ evolutionsFromFilter.length === 0 ? "EVOLUÇÕES" : "EVOLUÇÕES FILTRADAS" }</h2>
            { evolutionsContent() }
          </>
        ) : (
          <Loading pageName='evolutions' />
        )
      }
    </div>
  );
}
