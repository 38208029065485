
export const verifyStorageTime = (patientId) => {
  if (!localStorage.getItem("lastTimeStatusChanges")) {
    localStorage.setItem("lastTimeStatusChanges", JSON.stringify([]));
  }
  const statusTimeChanges = JSON.parse(localStorage.getItem("lastTimeStatusChanges"));
  const actualTime = new Date().getTime();
  const getPatientTime = statusTimeChanges.filter(time => time.id === patientId);
  const couldChangeStatus = !getPatientTime[0] || ((actualTime - getPatientTime[0]?.time) > 60000);
  const timeLeft = 60 - ((actualTime - getPatientTime[0]?.time) / 1000);

  return {
    statusTimeChanges,
    actualTime,
    getPatientTime,
    couldChangeStatus,
    timeLeft
  };
};

export const changePatientStatusStorage = (patientId) => {
  const timeFields = verifyStorageTime(patientId);
  if (!timeFields.getPatientTime[0]) {
    timeFields.statusTimeChanges.push({ id: patientId, time: timeFields.actualTime });
  } else {
    const patientTimeChangeIndex = timeFields.statusTimeChanges.findIndex(patient => (
      patient.id === patientId
    ));
    timeFields.statusTimeChanges[patientTimeChangeIndex].time = timeFields.actualTime;
  }
  localStorage.setItem("lastTimeStatusChanges", JSON.stringify(timeFields.statusTimeChanges));
};

export const getRelated = (related, options) => options.filter(rel => rel.value === related)[0]?.text;