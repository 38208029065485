import React, { useState } from "react";

import Button from "../layout/Button";
import Input from "../layout/Input";
import styles from "./PatientForm.module.css";
import ConfirmModal from "../confirmModal/ConfirmModal";
import { convertStringDateToExtendedDate } from "../../helpers/user_helper";
import Select from "../layout/Select";

export default function PatientForm({ btnText, handleSubmit, patient, newOne }) {
  const [confirm, setConfirm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [patientChanged, setPatientChange] = useState(
    patient ?
      {
        name: patient.name,
        birth: patient.birth,
        dad: patient.dad,
        mom: patient.mom,
        phone: patient.phone,
        value: patient.value
      } : {}
  );

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (
      !("name" in patientChanged)
      || !("value" in patientChanged)
      || !("birth" in patientChanged)
      || !(
        ("mom" in patientChanged && "name" in patientChanged.mom && patientChanged?.mom.name !== "")
        || ("dad" in patientChanged && "name" in patientChanged.dad && patientChanged?.dad.name !== "")
      )
    ) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor preencher todos os dados de paciente." }
          state={ "warn" }
          easyConfirm
          title="Ausencia de dados!"
          secondButton="fechar"
        />
      );
    } else if (convertStringDateToExtendedDate(patientChanged.birth) > 0) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor selecione uma data de nascimento válida." }
          state={ "warn" }
          easyConfirm
          title="Erro de data!"
          secondButton="fechar"
        />
      );
    } else {
      const createPatient = await handleSubmit(patientChanged);
      if (createPatient) setIsLoading(false);
    }
  };

  function handleChange(e, isInputOnSelect=false) {
    if (isInputOnSelect) {
      setPatientChange({ ...patientChanged, [e.target.name]: { related: e.target.related, name: e.target.value } });
    } else {
      setPatientChange({ ...patientChanged, [e.target.name]: e.target.value });
    }
  }

  const familyOptions = [
    { value: "dad", text: "PAI" },
    { value: "mom", text: "MÃE" },
    { value: "grandfather", text: "AVÔ" },
    { value: "grandmother", text: "AVÓ" },
    { value: "uncle", text: "TIO" },
    { value: "aunt", text: "TIA" }
  ];

  return (
    <>
      { confirm }
      <div className={ `${ styles["main"] } ${ styles[newOne && "cardful"] }` }>
        {patient && <h4>EDITAR PACIENTE</h4>}
        <form className={styles["form"]}>
          <div className={styles["row1"]}>
            <div className={styles["row1-content"]}>
              <Input
                text="NOME"
                name="name"
                type="text"
                handleOnChange={handleChange}
                value={patientChanged.name ? patientChanged.name : ""}
                fromModal={ Object.keys(patientChanged).length !== 0 }
              />
            </div>
          </div>
          <div className={styles["row2"]}>
            <div className={styles["row2-content"]}>
              <Input
                text="DATA DE NASC."
                disablePlaceHolder={true}
                name="birth"
                type="date"
                handleOnChange={handleChange}
                value={patientChanged.birth ? patientChanged.birth : ""}
                fromModal={ Object.keys(patientChanged).length !== 0 }
              />
            </div>
            <div className={styles["row2-content"]}>
              <Input
                text="TELEFONE"
                name="phone"
                type="text"
                handleOnChange={handleChange}
                value={patientChanged.phone ? patientChanged.phone : ""}
                fromModal={ Object.keys(patientChanged).length !== 0 }
              />
            </div>
            <div className={styles["row3-content"]}>
              <Input
                type="number"
                text="VALOR"
                name="value"
                handleOnChange={handleChange}
                value={patientChanged.value ? patientChanged.value : ""}
                fromModal={ Object.keys(patientChanged).length !== 0 }
              />
            </div>
          </div>
          <div className={styles["row3"]}>
            <div className={styles["row3-content"]}>
              <div className={styles["selectors"]}>
                <Select
                  title="RESPONSÁVEL"
                  name="dad"
                  optionsAray={ familyOptions }
                  value={patientChanged.dad?.name ? patientChanged.dad.name : ""}
                  related={ patientChanged.dad?.related ? patientChanged.dad.related : "" }
                  handleOnChange={ e => handleChange(e, true) }
                  fromModal
                  optionAsTitle
                  inputPlaceHolder="Nome..."
                />
              </div>
            </div>
            <div className={styles["row3-content"]}>
              <div className={styles["selectors"]}>
                <Select
                  title="RESPONSÁVEL"
                  name="mom"
                  optionsAray={ familyOptions }
                  value={patientChanged.mom ? patientChanged.mom.name : ""}
                  related={ patientChanged.mom ? patientChanged.mom.related : "" }
                  handleOnChange={ e => handleChange(e, true) }
                  fromModal
                  optionAsTitle
                  inputPlaceHolder="Nome..."
                />
              </div>
            </div>
          </div>
          <div className={styles["row4"]}>
            <Button
              text={btnText}
              handleOnClick={ submit }
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
    </>
  );
}
