import React from "react";
import { CiRead, CiUndo, CiTrash } from "react-icons/ci";

import Tooltip from "../evolution/Tooltip";
import styles from "./UsersList.module.css";

export default function UsersList({users, handleSubmit}) {
  const tooltipMessages = {
    viewUser: "visualizar pacientes",
    resetPasswd: "resetar senha",
    eraseUser: "apagar usuário"
  };

  const tooltipProps = {
    fontSize: "1rem",
    transform: "translateY(-3.7rem) translateX(-6.8rem)"
  };

  const content = () => {
    return (
      users.map((user) => (
        <li key={ user._id }>
          <span>{ user.name }</span>
          <div className={ styles["icons-container"] }>
            <Tooltip
              text={ tooltipMessages.viewUser }
              propStyles={ tooltipProps }
            >
              <div
                id={ `patients-${user.username}` }
                data-testid={ user._id }
                data-testname={ user.name }
                onClick={ handleSubmit }
                className={ styles["clickable"] }
              ></div>
              <CiRead className={ styles["readIcon"] }/>
            </Tooltip>
            <Tooltip
              text={ tooltipMessages.resetPasswd }
              propStyles={ tooltipProps }
            >
              <div
                id={ `reset-${user.username}` }
                data-testid={ user._id }
                data-testname={ user.name }
                onClick={ handleSubmit }
                className={ styles["clickable"] }
              ></div>
              <CiUndo className={ styles["undoIcon"] } />
            </Tooltip>
            <Tooltip
              text={ tooltipMessages.eraseUser }
              propStyles={ tooltipProps }
            >
              <div
                id={ `remove-${user.username}` }
                data-testid={ user._id }
                data-testname={ user.name }
                onClick={ handleSubmit }
                className={ styles["clickable"] }
              ></div>
              <CiTrash className={ styles["trashIcon"] } />
            </Tooltip>
          </div>
        </li>
      ))
    );
  };

  return (
    <div className={styles["list"]}>
      <h2>Usuários Cadastrados</h2>
      <ul>
        {users.length > 0 ?
          content() :
          <li><span>Não há usuários cadastrados.</span></li>
        }
      </ul>
    </div>
  );
}