import React, { useContext } from "react";

import { UserContext } from "../../context/UserContext";
import DashboardPatientsCard from "../dashboardPacientCard/DashboardPatientsCard";
import DashboardUserCard from "../dashboardUserCard/DashboardUserCard";
import Loading from "../layout/Loading";
import PageName from "../layout/PageName";
import styles from "./Dashboard.module.css";

export default function Dashboard() {
  const { user } = useContext(UserContext);

  return (
    <div className={styles["main"]}>
      {user ?
        <>
          <PageName title={ "Visão Geral" } />
          <div className={styles["content-container"]}>
            <div className={styles["left-column"]}>
              <DashboardPatientsCard />
            </div>
            <div className={styles["rigth-column"]}>
              <DashboardUserCard />
            </div>
          </div>
        </>
        :
        <Loading pageName='dashboard' />
      }
    </div>
  );
}
