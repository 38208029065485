import React, { useEffect, useState } from "react";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";

import styles from "./Input.module.css";

export default function Input({
  text,
  name,
  type,
  placeholder="",
  handleOnChange,
  value,
  handleOnInput,
  auxText,
  checked,
  id,
  disablePlaceHolder,
  disabled,
  fromModal
}) {
  const [placeHolder, setPlaceHolder] = useState(true);
  const [seePass, setSeePass] = useState(false);

  useEffect(() => {
    if(value || disablePlaceHolder) {
      setPlaceHolder(false);
    }
  }, [value]);

  function onFocus() {
    setPlaceHolder(false);
  }

  function onBlur(e) {
    if(!e.target.value) {
      setPlaceHolder(true);
    }
  }
  
  function passwd() {
    setSeePass(!seePass);
    switch (document.getElementById("password").type) {
    case "password":
      document.getElementById("password").type = "text";
      break;
    case "text":
      document.getElementById("password").type = "password";
      break;
    default:
      break;
    }
  }
  
  function passwdIcon() {
    if(seePass){
      return <FaRegEye />;
    } else {
      return <FaRegEyeSlash />;
    }
  }
  
  return (
    <div
      className={ `
        ${ styles["main"] }
        ${ styles[!text && "main-only-input"] }
        ${ styles[auxText && "main-aux"] }
        ${ styles[fromModal && "modal-input"] }
      ` }
    >
      {text &&
        <label
          className={`
            ${styles["placeHolderAsTitle"]}
            ${styles[(disablePlaceHolder && placeHolder) && "underlineAsNotPlaceHolder"]}
            ${styles[(!disablePlaceHolder && placeHolder) && "placeHolder"]}
          `}
          htmlFor={name}
        >
          {text}
        </label>
      }
      <div className={`
        ${styles[auxText && "input-aux-container"]}
        ${styles["input-container"]}
      `}>
        {
          type === "search" &&
            <div className={`${styles["search-icon"]}`}>
              <CiSearch />
            </div>
        }
        <input
          className={`
            ${styles["input"]}
            ${styles[auxText && "input-aux"]}
            ${ styles[fromModal && "modal-input"] }
            ${ styles[type === "search" && "search-input"] }
          `}
          type={type}
          name={name}
          id={id ? id : name}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          onInput={handleOnInput}
          checked={checked}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
        {
          type === "password" &&
            <div className={`${styles["eyeIcon"]}`} onClick={passwd}>
              {passwdIcon()}
            </div>
        }
        {
          auxText &&
            <div className={ `${ styles["aux-text-container"] }` }>
              <label>{ auxText }</label>
            </div>
        }
      </div>
    </div>
  );
}
