import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import Topography from "../layout/Topography";
import UsersList from "./UsersList";
import { MessageContext } from "../../context/MessageContext";
import { fetchOwnedUsers } from "../../endpoints/fetchUser";
import { systemMessages } from "../../helpers/messages";

export default function Accounts({ setCloseShowUsers, actualUser, handleSubmit }) {
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);

  const fetchUsers = async () => {
    const result = await fetchOwnedUsers( actualUser.id, cookies.access_token );
    if (result?.code === "M120") {
      setUsers(result?.ownedUsers);
    } else {
      const msg = systemMessages(result?.code);
      messageSetter(msg?.message, msg?.type);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [actualUser]);

  const handleOnSubmit = async e => {
    await handleSubmit(e);
    setCloseShowUsers(false);
  };

  return (
    <Topography
      handleOnClose={() => setCloseShowUsers(false)}
      clickOnBlur={() => setCloseShowUsers(false)}
    >
      <UsersList
        users={users}
        handleSubmit={ handleOnSubmit }
      />
    </Topography>
  );
}
