import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { UserContext } from "../../context/UserContext";
import { fetchCreateEvolution } from "../../endpoints/fetchEvolutions";
import { fecthCreatePatient, fetchExportPatients } from "../../endpoints/fetchPatients";
import { fetchCreateUser, fetchDeleteUser, fetchEditUser, fetchGetUser } from "../../endpoints/fetchUser";
import { encryptWithAES } from "../../helpers/user_helper";
import Accounts from "../accountsModal/Accounts";
import AddUser from "../adminOptions/AddUser";
import MyUser from "../adminOptions/MyUser";
import ConfirmModal from "../confirmModal/ConfirmModal";
import Divider from "../layout/Divider";
import Link from "../layout/Link";
import styles from "./DashboardUserCard.module.css";
import { systemMessages } from "../../helpers/messages";

export default function DashboardUserCard() {
  const { user, userSetter } = useContext(UserContext);
  const navigate = useNavigate([]);
  const [cookies, setCookies] = useCookies();
  const { messageSetter } = useContext(MessageContext);

  const [editAccount, setEditAccount] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [confirm, setConfirm] = useState();

  const logout = () => {
    setCookies("access_token", "");
    window.localStorage.clear();
    navigate("/");
  };

  const createUser = async (newUser) => {
    newUser.enterpriseName = user.enterpriseName;
    const result = await fetchCreateUser(newUser, user.id, cookies.access_token);
    if (result?.code === "M106") {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
      userSetter(result.user);
    } else {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
    }
    setAddUser(false);
    if (result) return !!result;
  };

  const editUser = async (editedUser) => {
    const result = await fetchEditUser(editedUser, user.id, cookies.access_token);
    if (result?.code === "M107") {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
      userSetter(result.user);
    } else {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
    }
    setEditAccount(false);
    if (result) return !!result;
  };

  const handleChangeUsers = e => {
    const userAction = e.target.id;
    const userId = e.target.dataset["testid"];
    const userName = e.target.dataset["testname"];
    const action = userAction.split("-");
    const name = String(userName);
    
    const confirmModalStrings = {
      reset: `Para resetar a senha de ${name} digite <span>resetar</span> e confirme.`,
      delete: `Para deletar o usuário ${name} digite <span>deletar</span> e confirme. Essa ação é irreverssível!`
    };

    const handleSubmit = async (inputText) => {
      if (inputText.value === "resetar" && action[0] === "reset") {
        const defaultPasswd = { "password": encryptWithAES("123456") };
        const result = await fetchEditUser(defaultPasswd, userId, cookies.access_token);
        if (result?.code === "M121") {
          const msg = systemMessages(result.code, name);
          messageSetter(msg.message, msg.type);
        } else {
          const msg = systemMessages(result.code);
          messageSetter(msg.message, msg.type);
        }
      }

      if (inputText.value === "deletar" && action[0] === "remove") {
        const result = await fetchDeleteUser(userId, cookies.access_token);
        if (result?.code === "M109") {
          const msg = systemMessages(result.code);
          messageSetter(msg.message, msg.type);
          userSetter(result.user);
        } else {
          const msg = systemMessages(result.code);
          messageSetter(msg.message, msg.type);
        }
      }

      setConfirm();
    };

    const confirmComponent = (text, state) => {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          handleSubmit={handleSubmit}
          text={ text }
          state={ state }
        />
      );
    };

    switch (action[0]) {
    case "reset":
      confirmComponent(confirmModalStrings.reset, "warn");
      break;
    case "remove":
      confirmComponent(confirmModalStrings.delete, "danger");
      break;
    case "patients":
      navigate("/patients", {
        state: {
          userName: userName,
          userId: userId
        }
      });
      break;
    default:
      break;
    }
  };

  const getUserLevel = level => {
    switch (level) {
    case "user":
      return "usuário";
    case "admin":
      return "administrador";
    case "dev":
      return "desenvolvedor";
    default:
      break;
    }
  };

  const handleOnExportPatients = async () => {
    const dateNow = new Date();
    const lastTimeExported = new Date(user?.lastTimeExported?.toString());
    if (
      !user.lastTimeExported
      || ((dateNow - lastTimeExported) / 1000) > 3600
    ) {
      const handleRequest = async () => {
        const excelFile = await fetchExportPatients(cookies.access_token);
        const response = await fetchGetUser(user.id, cookies.access_token);
        userSetter(response.user);
        const blob = new Blob(
          [excelFile],
          { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
        );
    
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Pacientes.xlsx";
        link.click();
        setConfirm();
      };
  
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          handleSubmit={handleRequest}
          text={ "Exportar pacientes? Esta ação só poderá ser refeita em uma hora." }
          state={ "warn" }
          easyConfirm
        />
      );
    } else setConfirm(
      <ConfirmModal
        setCloseConfirmModal={setConfirm}
        title="Erro ao exportar pacientes"
        text={ "Os pacientes já foram exportados à menos de uma hora. Favor espere o tempo mínimo para exportá-los novamente." }
        state={ "danger" }
        easyConfirm
        secondButton="fechar"
      />
    );
  };

  const handleConvert = async () => {
    await fetch("http://localhost:4000/users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((resp) => {
        if (!resp.ok) {
          throw new Error("Erro na solicitação: " + resp.statusText);
        }
        return resp.json();
      })
      .then(async (data) => {
        let silviaId = "65f4ec4e3da71ebf4ed58592"; // await user.id;
        data.forEach(async usuario => {
          const newUser = {
            username: usuario.username,
            password: encryptWithAES(usuario.password),
            level: usuario.level,
            name: usuario.name,
            credential: {
              registry: usuario.crp,
              type: "CRP"
            },
            enterpriseName: "munchenPsicologia"
          };
          const result = await fetchCreateUser(newUser, silviaId, cookies.access_token);
          if (usuario.username === "silvia") {
            silviaId = result.newUser.id;
          }
          usuario.patients.forEach(async paciente => {
            const newpaciente = {
              name: paciente.name,
              birth: paciente.birth ? paciente.birth : "2023-01-01",
              dad: paciente.dad ? paciente.dad : "não definido",
              mom: paciente.mom ? paciente.mom : "não definido",
              phone: paciente.phone ? paciente.phone : "0",
              value: paciente.value ? paciente.value : "0",
              isActive: paciente.isActive,
            };
            const newUserId = await result.newUser.id;
            const resultPaciente = await fecthCreatePatient(newpaciente, cookies.access_token, newUserId);
            const patientId = await resultPaciente.patientId;

            paciente.evolutions.forEach(async evolucao => {
              const newEvo = {
                isPresent: evolucao.evolutionPresence,
                isJustified: evolucao.evolutionJustify,
                date: evolucao.evolutionDate === "" ? "2022-01-01" : evolucao.evolutionDate,
                startHour: evolucao.evolutionInitialHour ? evolucao.evolutionInitialHour : "23:58",
                endHour: evolucao.evolutionFinalHour ? evolucao.evolutionFinalHour : "23h59",
                description: evolucao.evolutionDescription ? evolucao.evolutionDescription : "Evolução",
                observation: evolucao.observations ? evolucao.observations : "Sem observações.",
                patientOwner: patientId
              };
              await fetchCreateEvolution(newEvo, patientId, cookies.access_token);
            });
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (user) {
    return (
      <div className={ styles["account-card-container"] }>
        <div className={ styles["cardHeader"] }>
          <div className={ styles["title-container"] }>
            <p className={ styles["title-user"] }>{ user.name }</p>
            <span className={ styles["title-level"] }>{ getUserLevel(user.level) }</span>
          </div>
          <div className={ styles["link-separator"] }>
            <Link
              text={ "sair" }
              handleOnClick={ logout }
            />
          </div>
        </div>
        <Divider />
        <div className={styles["credential-container"]}>
          <p className={ styles["title-credential"] }>{ user.credential?.type.toUpperCase() }</p>
          <span>{ user.credential?.registry }</span>
        </div>
        <div className={ styles["card-footer"] }>
          <div className={styles["button-separator"]}>
            <Link
              disabled={ user && !user.hasAcceptedTOS }
              text={ "editar conta" }
              handleOnClick={ () => setEditAccount(true) }
            />
          </div>
          { (user.level === "admin" || user.level === "dev") && (
            <>
              <div className={styles["button-separator"]}>
                <Link
                  disabled={ user && !user.hasAcceptedTOS }
                  text={ "adicionar usuário" }
                  handleOnClick={ () => setAddUser(true) }
                />
              </div>
              <div className={styles["button-separator"]}>
                <Link
                  disabled={ user && !user.hasAcceptedTOS }
                  text={ "visualizar usuários" }
                  handleOnClick={ () => setShowAllUsers(true) }
                />
              </div>
            </>
          ) }
          <div className={styles["button-separator"]}>
            <Link
              text={ "exportar pacientes" }
              handleOnClick={ handleOnExportPatients }
            />
          </div>
          { user.level === "dev" &&
            <div className={styles["button-separator"]}>
              <Link
                text={ "converter pacientes" }
                handleOnClick={ handleConvert }
              />
            </div>
          }
        </div>
        { confirm }
        { editAccount && 
          <MyUser
            setCloseEditUser={ setEditAccount }
            user={ user }
            handleSubmit={ editUser }
          />
        }
        { addUser &&
          <AddUser
            setCloseAddUser={ setAddUser }
            handleSubmit={ createUser }
          />
        }
        { showAllUsers &&
          <Accounts
            setCloseShowUsers={ setShowAllUsers }
            actualUser={ user }
            handleSubmit={ handleChangeUsers }
          />
        }
      </div>
    );
  }
}
