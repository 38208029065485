import React, { useRef, useState } from "react";

import styles from "./Tooltip.module.css";

const Tooltip = ({ text, children, propStyles }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const containerRef = useRef();
  const tooltipRef = useRef();

  const defineTooltipRects = async () => {
    const statusRects = await containerRef.current.getBoundingClientRect();
    const tooltipRects = await tooltipRef.current.getBoundingClientRect();
    const signal = (tooltipRects.left < statusRects.left) ? "-" : "+";
    setTooltipPosition({
      left: `${signal}${
        (((tooltipRects.width / 2) - (statusRects.width / 2)) / statusRects.width)
      }rem`,
      top: `${ (statusRects.height + 5 - 15) / 10 }rem`
    });
  };

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
    defineTooltipRects();
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      ref={ containerRef }
      className={ styles["tooltip-container"] }
    >
      <div
        className={ styles["children-container"] }
        onMouseEnter={ handleMouseEnter }
        onMouseLeave={ handleMouseLeave }
      >
        { children }
      </div>
      <div
        ref={ tooltipRef }
        className={ styles["tooltip"] }
        style={ {
          opacity: isTooltipVisible ? "1" : "0",
          ...propStyles,
          ...tooltipPosition
        } }
      >
        { text }
      </div>
    </div>
  );
};

export default Tooltip;
