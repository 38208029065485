import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";

import styles from "./Select.module.css";
import { getRelated } from "../../helpers/patient_helper";

export default function Select({
  title,
  handleOnChange,
  value,
  disablePlaceHolder,
  disabled,
  name,
  optionsAray=[],
  fromModal,
  optionAsTitle,
  related,
  inputPlaceHolder
}) {
  const [placeHolder, setPlaceHolder] = useState(true);
  const [arrowAngle, setArrowAngle] = useState(0);

  useEffect(() => {
    if(value || disablePlaceHolder || related) {
      setPlaceHolder(false);
    }
  }, [value]);

  const onFocus = () => {
    setPlaceHolder(false);
    setArrowAngle(180);
  };

  const onBlur = (e) => {
    if((!optionAsTitle && e.target.value === "100") || (optionAsTitle && !related)) {
      setPlaceHolder(true);
    }
    setArrowAngle(0);
  };

  const onChange = (e) => {
    if (optionAsTitle) {
      handleOnChange({
        target: {
          name: name,
          value: e.target.type === "text" ? e.target.value : value,
          related: e.target.type === "text" ? related : e.target.value
        }
      });
    } else {
      handleOnChange(e);
    }
  };
  
  return (
    <div
      className={ `
        ${ styles["main"] }
        ${ styles[fromModal && "modal"] }
      ` }
    >
      {title &&
        <label
          className={`
            ${styles["placeHolderAsTitle"]}
            ${styles[(placeHolder) && "placeHolder"]}
          `}
          htmlFor={ name }
        >
          { (optionAsTitle && related) ? getRelated(related, optionsAray) : title }
        </label>
      }
      <div
        className={`
            ${styles["select-container"]}
        `}
      >
        <select
          className={`
            ${ styles["select"] }
            ${ styles[!placeHolder && "select-focused"] }
          `}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={ onChange }
          disabled={ disabled }
          name={ name }
          id={ name }
          value={ (optionAsTitle && !value) && value }
        >
          <option key="100" value="100" hidden></option>
          { optionsAray.map((option, key) => {
            return <option key={ key } value={ option.value }>{ option.text }</option>;
          })
          }
        </select>
        <div
          className={ `
                ${ styles["select-arrow"] }
            ` }>
          <FaAngleDown
            style={ { transform: `rotate(${ arrowAngle }deg)` } }
          />
        </div>
        { optionAsTitle &&
          <input
            className={ styles["textInput"] }
            id="textInput"
            onChange={ onChange }
            style={ {
              zIndex: `${ (optionAsTitle && related) ? 5 : 3 }`,
              opacity: `${ (optionAsTitle && related) ? 1 : 0 }`
            } }
            defaultValue={ value }
            placeholder={ inputPlaceHolder }
            onFocus={ onFocus }
            onBlur={ onBlur }
            autoComplete="off"
          />
        }
      </div>
    </div>
  );
}
