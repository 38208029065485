import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import styles from "./App.module.css";
import LogoutCard from "./components/expirationCard/LogoutCard";
import Footer from "./components/layout/Footer";
import Message from "./components/layout/Message";
import Navbar from "./components/layout/Navbar";
import PageError from "./components/layout/PageError";
import Dashboard from "./components/pages/Dashboard";
import Evolutions from "./components/pages/Evolutions";
import Home from "./components/pages/Home";
import NewPatient from "./components/pages/NewPatient";
import Patients from "./components/pages/Patients";
import TermsModal from "./components/termsOfService/TermsModal";
import { EvolutionsProvider } from "./context/EvolutionsContext";
import { MessageContext } from "./context/MessageContext";
import { PatientsProvider } from "./context/PatientsContext";
import { UserContext } from "./context/UserContext";
import { fetchGetUser } from "./endpoints/fetchUser";
import { getPathName } from "./helpers/environment_helper";
import { systemMessages } from "./helpers/messages";

export default function App() {
  const loginPath = getPathName() === "/";
  const dashPath = getPathName() === "/dashboard";
  const basePath = window.location.origin;
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);
  const { user, userSetter } = useContext(UserContext);
  const hasLoggedUser = cookies.access_token && localStorage.getItem("userId");

  const fetchUser = async () => {
    const result = await fetchGetUser(localStorage.getItem("userId"), cookies.access_token);
    if (result?.code === "M100") {
      const msg = systemMessages(result?.code, result.user.name);
      messageSetter(msg.message, msg.type);
      userSetter(result.user);
    } else {
      const msg = systemMessages(result?.code);
      messageSetter(msg.message, msg.type);
    }
  };

  useEffect(() => {
    if(localStorage.getItem("userId") && cookies.access_token) {
      fetchUser();
    }
  }, [localStorage.getItem("userId"), cookies.access_token]);

  useEffect(() => {
    if (!loginPath && !dashPath && user && !user.hasAcceptedTOS) {
      window.location.href = `${ basePath }/dashboard`;
    }
  }, [user]);
  
  return (
    <div
      className={ `${ styles["app"] }` }
      id={"app"}
    >
      <TermsModal />
      <Message />
      <Router>
        <LogoutCard>
          <div
            className={ `
              ${ styles[loginPath && "navbar-container-login-page"] }
              ${ styles["navbar-container"] }
            ` }>
            <Navbar />
          </div>
          <div
            className={ `
              ${ styles["portal-container"] }
              ${ styles[loginPath && "portal-container-login-page"] }
            ` }
          >
            <div
              className={ `
                ${ styles["router-content"] }
                ${ styles[loginPath && "router-content-login-page"] }
              ` }
            >
              <Routes>
                <Route
                  exact path="/"
                  element={ <Home /> }
                />
                <Route
                  exact path="/dashboard"
                  element={ hasLoggedUser ?
                    <Dashboard /> :
                    <PageError pageName={"root"} />
                  }
                />
                <Route
                  exact path="/newpatient"
                  element={ hasLoggedUser ?
                    <PatientsProvider>
                      <NewPatient />
                    </PatientsProvider> :
                    <PageError pageName={"root"} />
                  }
                />
                <Route
                  exact path="/patients"
                  element={ hasLoggedUser ?
                    <PatientsProvider>
                      <Patients />
                    </PatientsProvider> :
                    <PageError pageName={"root"} />
                  }
                />
                <Route
                  exact path="/evolutions/:patientId"
                  element={
                    <PatientsProvider>
                      <EvolutionsProvider>
                        <Evolutions />
                      </EvolutionsProvider>
                    </PatientsProvider>
                  }
                />
              </Routes>
            </div>
            <Footer />
          </div>
        </LogoutCard>
      </Router>
    </div>
  );
}
