import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { CiClock1 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

import Button from "../layout/Button";
import Topography from "../layout/Topography";
import styles from "./LogoutCard.module.css";

export default function LogoutCard(props) {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["access_token"]);
  const [showLogoutCard, setShowLogoutCard] = useState(false);
  let timerUntilLogout;

  const doLogout = () => {
    navigate("/");
    setShowLogoutCard(true);
    setCookies("access_token", null);
    window.localStorage.clear();
  };

  const resetInactivityTimer = () => {
    if (
      localStorage.getItem("@user/activityChecker") === "true"
      && cookies.access_token
    ) {
      clearTimeout(timerUntilLogout);
      timerUntilLogout = setTimeout(doLogout, 900000);
    }
  };

  window.addEventListener("mousemove", resetInactivityTimer);
  window.addEventListener("keydown", resetInactivityTimer);

  const onClose = () => setShowLogoutCard(false);

  return (
    <>
      {showLogoutCard &&
        <Topography
          handleOnClose={ onClose }
        >
          <div className={styles["main"]}>
            <div className={ styles["title"] }>
              <CiClock1 />
              <h4>Você foi deslogado por inatividade!</h4>
            </div>
            <p>
              Favor refaça o login para acessar o sistema.
            </p>
            <div className={ styles["button-container"] }>
              <Button
                text={ "fechar" }
                handleOnClick={ onClose }
              />
            </div>
          </div>
        </Topography>
      }
      {props.children}
    </>
  );
}