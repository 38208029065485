import CryptoJS from "crypto-js";

const passphrase = "123";

export const getUserLevel = () => {
  return window.localStorage.getItem("userLevel");
};

export const encryptWithAES = (text) => {
  var ciphertext = CryptoJS.AES.encrypt(text, passphrase).toString();
  return ciphertext;
};

export const convertStringDateToExtendedDate = (date) => {
  const today = new Date();
  const splitDate = date.split("-");

  const correctMonth = (Number(splitDate[1]) - 1).toString();
  const extendedDate = new Date(splitDate[0], correctMonth, splitDate[2]);

  return extendedDate - today;
};