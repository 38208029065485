import axios from "axios";

import { getEnvironmentUrl } from "../helpers/environment_helper";

const baseUrl = getEnvironmentUrl();

export const fetchGetPatients = async (userId) => {
  try {
    const response = await axios.get(
      `${ baseUrl }/patients/${
        userId
          ? userId
          : localStorage.getItem("userId")
      }/patients`
    );
    return response.data.ownedPatients;
  } catch (error) {
    console.log(error);
  }
};

export const fecthCreatePatient = async (newPatient, accessToken, diffUserId) => {
  try {
    const result = await axios.post(
      `${baseUrl}/patients/${diffUserId ? diffUserId : localStorage.getItem("userId") }/create`,
      newPatient,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchChangePatient = async (patientChanges, patientId, accessToken) => {
  try {
    const patientChanged = await axios.put(
      `${ baseUrl }/patients/${ patientId }/change_patient`,
      patientChanges,
      { headers: { authorization: accessToken } }
    );
    return patientChanged.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDeletePatient = async (patientId, accessToken) => {
  try {
    const result = await axios.delete(
      `${ baseUrl }/patients/${ localStorage.getItem("userId") }/${ patientId }/delete_patient`,
      { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchExportPatients = async (accessToken) => {
  try {
    const result = await axios.get(
      `${ baseUrl }/patients/${ localStorage.getItem("userId") }/export`,
      {
        headers: { authorization: accessToken },
        responseType: "blob"
      }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};