import React, { createContext, useState } from "react";

export const PatientsContext = createContext();

export const PatientsProvider = ({ children }) => {
  const [patients, setPatients] = useState(null);

  const patientsSetter = (patients) => {
    setPatients(patients);
  };

  const providerValues ={
    patients,
    patientsSetter
  };

  return (
    <PatientsContext.Provider
      value={ providerValues }
    >
      { children }
    </PatientsContext.Provider>
  );
};