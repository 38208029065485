import React, { useState, useContext, useEffect } from "react";

import { MessageContext } from "../../context/MessageContext";
import styles from "./Message.module.css";

export default function Message() {
  const [visible, setVisible] = useState(false);
  const { message, messageSetter } = useContext(MessageContext);
  const [nextMessage, setNextMessage] = useState(null);

  const removeMessageFromProvider = () => {
    setTimeout(() => {
      messageSetter(null, null, true);
    }, 1200);
  };

  const hideMessage = () => {
    setTimeout(() => {
      setVisible(false);
      removeMessageFromProvider();
    }, 4000);
  };

  const showMessage = () => {
    if (message.length > 0 && !visible) {
      setTimeout(() => {
        setNextMessage(message[0]);
        setVisible(true);
        hideMessage();
      }, 700);
    }
  };
  
  useEffect(() => {
    showMessage();
  }, [message]);

  return (
    <>
      { nextMessage &&
        <div
          className={ `
            ${ styles["main"] }
            ${ styles[!visible && "hide-message"] }
          ` }
        >
          <div
            id="message"
            className={ `
              ${ styles["message-container"] }
              ${ styles[nextMessage.type] }
            ` }
          >
            { nextMessage.message }
          </div>
        </div>
      }
    </>
  );
}
