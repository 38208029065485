import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const userSetter = (user) => {
    setUser(user);
  };

  const providerValues ={
    user,
    userSetter,
  };

  return (
    <UserContext.Provider
      value={ providerValues }
    >
      { children }
    </UserContext.Provider>
  );
};