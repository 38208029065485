import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { fetchLoginUser } from "../../endpoints/fetchUser";
import { encryptWithAES } from "../../helpers/user_helper";
import Input from "../layout/Input";
import Link from "../layout/Link";
import styles from "./Login.module.css";
import { systemMessages } from "../../helpers/messages";

export default function Login() {
  const navigate = useNavigate([]);
  // eslint-disable-next-line no-unused-vars
  const [_, setCookies] = useCookies(["access_token"]);
  const { messageSetter } = useContext(MessageContext);
  const [userLogging, setUserLogging] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const result = await fetchLoginUser(userLogging);
    if (result?.code === "M118") {
      setCookies("access_token", result.token);
      navigate("/dashboard");
      const msg = systemMessages(result?.code);
      messageSetter(msg?.message, msg?.type);
    } else {
      const msg = systemMessages(result?.code);
      messageSetter(msg?.message, msg?.type);
    }
  };

  const handleChange = async (e, passwd=false) => {
    setUserLogging({
      ...userLogging,
      [e.target.name]:
        passwd
          ? encryptWithAES(e.target.value)
          : e.target.value
    });
  };

  return (
    <div className={ styles["loginContainer"] }>
      <form className={ styles["loginForm"] } onSubmit={ handleOnSubmit } >
        <Input
          text='LOGIN'
          type='text'
          name='username'
          handleOnChange={ handleChange }
        />
        <Input
          text='SENHA'
          type='password'
          name='password'
          handleOnChange={ (e) => handleChange(e, true) }
        />
        <div className={ styles["login-button"] } >
          <button>
            <Link text={ "acessar" } />
          </button>
        </div>
      </form>
    </div>
  );
}
