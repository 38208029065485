import emailjs from "@emailjs/browser";
import { publicIpv4 } from "public-ip";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { UserContext } from "../../context/UserContext";
import { fetchEditUser } from "../../endpoints/fetchUser";
import Button from "../layout/Button";
import Topography from "../layout/Topography";
import styles from "./TermsModal.module.css";
import TermsOfService from "./TermsOfService";
import { systemMessages } from "../../helpers/messages";
import { MessageContext } from "../../context/MessageContext";

export default function TermsModal() {
  const [showTOS, setShowTOS] = useState(false);
  const { user, userSetter } = useContext(UserContext);
  const { messageSetter } = useContext(MessageContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const [buttonBlocker, setButtonBlocker] = useState(true);

  useEffect(() => {
    if (user && !user.hasAcceptedTOS) setShowTOS(true);
  }, [user]);
  
  async function acceptTerms(e) {
    e.preventDefault();
    const result = await fetchEditUser({ hasAcceptedTOS: true }, localStorage.getItem("userId"), cookies.access_token);
    if (result?.code === "M122") {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
      userSetter(result.user);
    } else {
      const msg = systemMessages(result.code);
      messageSetter(msg.message, msg.type);
    }

    const ip = String(await publicIpv4());

    const dateNow = new Date;
    const gdh = dateNow.toISOString();
    const getActualDate = gdh.substring(0, 10);
    const getActualHour = gdh.substring(11, 19);
    const date = `${getActualDate} ${getActualHour}`;

    const url = document.location.href;

    const project = document.location.hostname.split(".");

    const clientInfo = {
      "ip": ip,
      "date": date,
      "url": url,
      "userLogged": user.name,
      "project": project[0]
    };

    emailjs.send("service_sg1qqjc", "template_bd55bbj", clientInfo, "ZiC2wFWylL_x0XUCp");
    setShowTOS(false);
  }

  return (
    <>
      { showTOS &&
        <Topography>
          <div className={`${styles["container"]}`}>
            <div className={`${styles["title"]}`}>Termos de Uso</div>
            <div className={`${styles["body"]}`}>
              <div className={`${styles["content"]}`}>
                <TermsOfService blockButton={ setButtonBlocker } />
              </div>
              <div className={`${styles["footer"]}`}>
                <div className={`${styles["button"]}`}>
                  <Button
                    text={"não concordo"}
                    handleOnClick={() => setShowTOS(false)}
                    secondary
                  />
                </div>
                <div className={`${styles["button"]}`}>
                  <Button
                    text={"concordo"}
                    handleOnClick={(e) => acceptTerms(e)}
                    disabled={ buttonBlocker }
                  />
                </div>
              </div>
            </div>
          </div>
        </Topography>
      }
    </>
  );
}