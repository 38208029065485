import React from "react";

import styles from "./Link.module.css";

export default function Link({
  text,
  handleOnClick,
  href,
  fromModal,
  fromMenu,
  disabled
}) {
  return (
    <div
      className={ `
        ${ styles["link-container"] }
        ${ styles[fromModal && "modal-link"] }
        ${ styles[fromMenu && "menu-link"] }
      ` }
    >
      <a
        className={styles["link"]}
        onClick={!disabled ? handleOnClick : undefined}
        href={ href }
      >
        <span>{ text }</span>
      </a>
    </div>
  );
}
