import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import { MessageContext } from "../../context/MessageContext";
import { fetchCreateEvolution } from "../../endpoints/fetchEvolutions";
import ConfirmModal from "../confirmModal/ConfirmModal";
import Button from "../layout/Button";
import Input from "../layout/Input";
import Select from "../layout/Select";
import styles from "./EvolutionForm.module.css";
import { convertStringDateToExtendedDate } from "../../helpers/user_helper";

export default function EvolutionForm({ btnText, onCreate }) {
  const { patientId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [cookies, _] = useCookies();
  const { messageSetter } = useContext(MessageContext);
  const [evolution, setEvolution] = useState({});
  const [confirm, setConfirm] = useState();

  // Create a evolution and post it
  const createEvolution = async (evolution) => {
    const result = await fetchCreateEvolution(evolution, patientId, cookies.access_token);
    if (result) {
      messageSetter("Evolução criada com sucesso!", "success");
      onCreate(result);
    } else {
      messageSetter("Erro de conexão com o servidor.", "error");
    }
  };

  function submit(e) {
    e.preventDefault();
    if (
      !("date" in evolution)
      || !("description" in evolution)
      || !("observation" in evolution)
      || !("startHour" in evolution)
      || !("endHour" in evolution)
    ) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor preencher todos os dados da consulta." }
          state={ "warn" }
          easyConfirm
          title="Ausencia de dados!"
          secondButton="fechar"
        />
      );
    } else if (convertStringDateToExtendedDate(evolution.date) > 0) {
      setConfirm(
        <ConfirmModal
          setCloseConfirmModal={setConfirm}
          text={ "Favor selecione uma data válida." }
          state={ "warn" }
          easyConfirm
          title="Erro de data!"
          secondButton="fechar"
        />
      );
    } else {
      createEvolution(evolution);
    }
  }

  const selectPresence = value => {
    switch (value) {
    case "0":
      return false;
    case "1":
      return true;
    default:
      return false;
    }
  };

  function handleChange(e) {
    if(e.target.name === "isPresent" || e.target.name === "isJustified") {
      setEvolution({
        ...evolution,
        [e.target.name]: selectPresence(e.target.value)
      });
    } else {
      setEvolution({ ...evolution, [e.target.name]: e.target.value });
    }
  }

  const presenceOptions = [
    { value: "1", text: "presente" },
    { value: "0", text: "ausente" }
  ];
  
  const justifyOptions = [
    { value: "1", text: "justificada" },
    { value: "0", text: "injustificada" }
  ];

  return (
    <>
      { confirm }
      <div className={styles["main"]}>
        <h4>FORMULÁRIO DE EVOLUÇÃO</h4>
        <form className={styles["form"]}>
          <div className={ styles["row1"] }>
            <div className={ styles["row1-content"] }>
              <Input
                type="date"
                text="DATA DA EVOLUÇÃO"
                name="date"
                handleOnChange={handleChange}
                disablePlaceHolder
                fromModal
              />
            </div>
            <div className={ styles["row1-content"] }>
              <Input
                type="time"
                text="HORA INICIAL"
                name="startHour"
                handleOnChange={handleChange}
                disablePlaceHolder
                fromModal
              />
            </div>
            <div className={ styles["row1-content"] }>
              <Input
                type="time"
                text="HORA FINAL"
                name="endHour"
                handleOnChange={handleChange}
                disablePlaceHolder
                fromModal
              />
            </div>
          </div>
          <div className={ styles["row2"] }>
            <div className={styles["selectors"]}>
              <Select
                title="PRESENÇA"
                name="isPresent"
                optionsAray={ presenceOptions }
                handleOnChange={ handleChange }
                fromModal
              />
            </div>
            <div className={styles["selectors"]}>
              <Select
                title="JUSTIFICAÇÃO"
                name="isJustified"
                optionsAray={ justifyOptions }
                handleOnChange={ handleChange }
                fromModal
              />
            </div>
          </div>
          <div className={ styles["row3"] }>
            <Input
              type="text"
              text="DESCRIÇÃO"
              name="description"
              handleOnChange={handleChange}
              fromModal
            />
          </div>
          <div className={ styles["row4"] }>
            <textarea
              wrap="soft"
              placeholder="Descreva a evolução obtida..."
              name="observation"
              onChange={handleChange}
            ></textarea>
          </div>
          <div className={ styles["row5"] }>
            <Button text={btnText} handleOnClick={ submit } />
          </div>
        </form>
      </div>
    </>
  );
}
