import axios from "axios";

import { getEnvironmentUrl } from "../helpers/environment_helper";

const baseUrl = getEnvironmentUrl();

export const fetchGetEvolutions = async (patientId) => {
  try {
    const patientWithEvolutions =
        await axios.get(
          `${ baseUrl }/evolutions/${ patientId }/${ localStorage.getItem("userId") }/evolutions`
        );
    return patientWithEvolutions.data.patientWithEvolutions[0];
  } catch (error) {
    console.log(error);
  }
};

export const fetchDeleteEvolution = async (evolutions, evolutionId, accessToken) => {
  try {
    const result = await axios.delete(
      `${ baseUrl }/evolutions/${ evolutionId }/delete_evolution`,
      { headers: { authorization: accessToken } }
    );
    const remainingEvolutions =
      evolutions.filter(evolution => evolution._id !== evolutionId);
    return { remainingEvolutions, user: result.data.user };
  } catch (error) {
    console.log(error);
  }
};

export const fetchCreateEvolution = async (evolution, patientId, accessToken) => {
  if (!Object.prototype.hasOwnProperty.call(evolution, "isPresent")) evolution.isPresent = false;
  if (!Object.prototype.hasOwnProperty.call(evolution, "isJustified")) evolution.isJustified = false;
  try {
    const result = await axios.post(`
      ${ baseUrl }/evolutions/${ patientId }/${ localStorage.getItem("userId") }/add`,
    evolution,
    { headers: { authorization: accessToken } }
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};