
import React, { useContext, useEffect, useState } from "react";
import { CiTrash } from "react-icons/ci";

import { MessageContext } from "../../context/MessageContext";
import { isoDateFormatToBrazil } from "../../helpers/environment_helper";
import ConfirmModal from "../confirmModal/ConfirmModal";
import styles from "./EvolutionCard.module.css";
import Tooltip from "./Tooltip";

export default function EvolutionCard(props) {
  const { messageSetter } = useContext(MessageContext);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [confirm, setConfirm] = useState();

  // Delete the specified evolution and reload all evolutions

  const remove = (e) => {
    e.preventDefault();

    const deleteEvolution = async inputText => {
      if (inputText.value === "excluir") {
        props.handleDeleteEvolution();
        setConfirm();
        messageSetter("Evolução removida com sucesso!", "success");
      } else {
        setConfirm();
      }
    };

    setConfirm(
      <ConfirmModal
        setCloseConfirmModal={setConfirm}
        handleSubmit={ deleteEvolution }
        text={ "Digite a palavra <span>excluir</span>, para prosseguir com a exclusão." }
        state={ "danger" }
      />
    );
  };

  const statusOfPresence = () => {
    if (props.evolution.isPresent) {
      return "present";
    } else if (props.evolution.isJustified && !props.evolution.isPresent) {
      return "ausentJustifed";
    } else {
      return "ausent";
    }
  };

  const colorStatus = () => {
    switch (statusOfPresence()) {
    case "present":
      return "#78be99";
    case "ausentJustifed":
      return "#eebc7e";
    case "ausent":
      return "#F37471";
    default:
      return "#a5a5a56c";
    }
  };

  const tooltipStatus = () => {
    switch (statusOfPresence()) {
    case "present":
      setTooltipMessage("Paciente presente na sessão. Contabilizando o valor.");
      break;
    case "ausentJustifed":
      setTooltipMessage("Paciente ausente na sessão, com justificativa válida. Valor não contabilizado.");
      break;
    case "ausent":
      setTooltipMessage("Paciente ausente na sessão, sem justificativa válida. Contabilizando o valor.");
      break;
    default:
      setTooltipMessage("Informações inválidas, favor recarregue a página!");
      break;
    }
  };

  useEffect(() => {
    tooltipStatus();
  }, []);

  const tooltipPropsStatus = {
    width: "12rem",
    transform: "translateX(-6rem)"
  };
  
  const tooltipPropsRemove = {
    fontSize: "1rem",
    transform: "translateY(-3.8rem) translateX(-6rem)"
  };

  return (
    <>
      { confirm }
      <div className={ styles["evolution-card-container"] }>
        <div className={ styles["evolution-card-description"] }>
          <div className={ styles["evolution-card-description-container"] }>
            <h4>{props.evolution.description}</h4>
            <Tooltip text={ tooltipMessage } propStyles={ tooltipPropsStatus }>
              <div
                style={ { backgroundColor: colorStatus() } }
                className={ styles["status"] }
              ></div>
            </Tooltip>
          </div>
          <span dangerouslySetInnerHTML={ { __html: props.evolution.observation.replace(/\n/g, "<br>") } } />
        </div>
        <div className={ styles["evolution-card-informations"] }>
          <div className={ styles["gdh-status"] }>
            <div>
              <span className={ styles["title"] }>HORA INICIAL: </span>
              <span className={ styles["info"] }>{ props.evolution.startHour }</span>
            </div>
            <div>
              <span className={ styles["title"] }>HORA FINAL: </span>
              <span className={ styles["info"] }>{ props.evolution.endHour }</span>
            </div>
            <div>
              <span className={ styles["info"] }>{isoDateFormatToBrazil(props.evolution.date)}</span>
            </div>
          </div>
          <div className={ styles["information-status"] }>
            {
              !props.evolution.isPresent &&
                <div className={ styles["information-status-subcontainer"] }>
                  <span>
                    { props.evolution.isJustified ? "JUSTIFICADA" : "NÃO JUSTIFICADA" }
                  </span>
                </div>
            }
            <div className={ styles["information-status-subcontainer"] }>
              <span>
                { props.evolution.isPresent ? "PRESENTE": "AUSENTE" }
              </span>
            </div>
            <div className={ styles["information-status-subcontainer"] }>
              <Tooltip
                text="excluir evolução"
                propStyles={ tooltipPropsRemove }
              >
                <button className={ styles["remove-button"] } onClick={remove}>
                  <CiTrash />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
