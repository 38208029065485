import React from "react";

import UserForm from "../dashboardUserCard/userForm";
import Topography from "../layout/Topography";

export default function MyUser({ setCloseEditUser, user, handleSubmit }) {
  return (
    <Topography
      handleOnClose={ () => setCloseEditUser(false) }
      clickOnBlur={ () => setCloseEditUser(false) }
      customClass="modal"
    >
      <UserForm
        user={user}
        btnText="salvar perfil"
        handleSubmit={handleSubmit}
        formTitle="Editar Perfil"
      />
    </Topography>
  );
}
